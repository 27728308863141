.dropdownIcon {
  padding: 0 !important;
  width: 1rem;
  justify-content: center;
}

.table {
  margin-top: 1.5rem;
  table {
    /* Aligning table columns across various benefit categories
     is more important than fitting everything without a horizontal scrolling */
    width: 100%;
    table-layout: fixed;

    th:last-child {
      width: auto !important;
    }
  }
}

.badge {
  inline-size: fit-content;
  white-space: nowrap;
  display: flex;
  align-items: center;
  span {
    min-width: 80px;
  }
}

.cardMargin {
  margin-top: 1rem;
  padding-bottom: 0.75rem;
}
