import { DatePicker, HelperText } from '@component-library/helios'
import { DateTime } from 'luxon'
import { type FC, useRef, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { DATE_OF_BIRTH } from '../../../common/enums/regex'

import * as styles from './Employment.module.css'

const {
  MM_DD_YYYY: { regex, pattern },
} = DATE_OF_BIRTH

export const SignatureDate: FC = () => {
  const { control, getValues } = useFormContext()
  const { t } = useTranslation('translation', { keyPrefix: 'Employment' })
  const inputRef = useRef<HTMLInputElement>(null)

  const isDateValid = (dateString: { input: string }): boolean => {
    const sigDate = DateTime.fromFormat(dateString.input, 'MM/dd/yyyy')
    const formatHireDate = DateTime.fromFormat(getValues('hireDate')?.input, 'MM/dd/yyyy')

    if (sigDate.invalidReason) {
      return false
    }

    if (sigDate < formatHireDate) {
      return false
    }

    return true
  }

  const validateSigDate = (dateString: { input: string }): true | string => {
    if (dateString.input === '') {
      return t('PLEASE_MAKE_SELECTION')
    }

    if (isDateValid(dateString)) {
      return true
    }
    return t('INVALID_DATE')
  }

  return (
    <>
      <div className={styles.justBottomMargin} data-testid="sigDate">
        <Controller
          rules={{
            required: {
              value: true,
              message: t('PLEASE_MAKE_SELECTION'),
            },
            pattern: {
              value: DATE_OF_BIRTH.MM_DD_YYYY.regex,
              message: DATE_OF_BIRTH.MM_DD_YYYY.message,
            },
            validate: validateSigDate,
          }}
          control={control}
          name="signatureDate"
          render={({ field, fieldState }) => {
            const { ...fields } = field
            return (
              <>
                <div>
                  <DatePicker
                    {...fields}
                    id={field.name}
                    label={t('SIG_DATE_LABEL')}
                    error={fieldState?.invalid}
                    className=""
                    placeholder={''}
                    defaultValue={undefined}
                    minDate=""
                    maxDate=""
                    isModal={false}
                    inputProps={undefined}
                    inputRef={inputRef}
                    localeTexts={undefined}
                  />
                </div>
                {fieldState.invalid && (
                  <div>
                    <HelperText id={`sig-date-error`} error>
                      {fieldState.error?.message}
                    </HelperText>
                  </div>
                )}
              </>
            )
          }}
        />
      </div>
    </>
  )
}
