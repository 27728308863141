.stack {
  display: flex;
  flex-direction: column;
}

.growFirst {
  :first-child {
    flex-grow: 1;
  }
}
