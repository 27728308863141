import { useState, useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { trackView } from '../utils/analytics'

import { useAuth } from './useAuth'

export function useViewMemberManagementTracker() {
  const location = useLocation()
  const auth = useAuth()
  const [stack, setStack] = useState<Array<string>>([])

  const track = useCallback(function ({ url }: { url: string }) {
    trackView({
      ev_type: 'page_imp',
      ev_action: 'page_imp',
      page_canonical_url: url,
    })
  }, [])

  useEffect(() => {
    let timeoutId: NodeJS.Timeout
    function tick() {
      if (stack.length) {
        stack.forEach((url) =>
          track({
            url,
          }),
        )
        setStack([])
      }
      timeoutId = setTimeout(tick, 100)
    }
    tick()
    return () => {
      clearInterval(timeoutId)
    }
  }, [stack, track])

  return function (url: string) {
    setStack((stack) => [...stack, url])
  }
}
