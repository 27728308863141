import { useContext, useEffect, type FC } from 'react'
import { FormProvider, useForm, type UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AddMemberContext, type DropdownValue } from '../../../context/AddMemberContext'
import { AddMemberProgressContext } from '../../../context/AddMemberProgressContext'

import * as styles from './Billing.module.css'
import { BillingLocation } from './BillingLocation'

interface DefaultBillingValues {
  billingLocation: DropdownValue | undefined
}

const defaultBillingValues: DefaultBillingValues = {
  billingLocation: undefined,
}

export const Billing: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'Billing' })
  const methods: UseFormReturn<DefaultBillingValues> = useForm<DefaultBillingValues>({
    mode: 'onChange',
    defaultValues: defaultBillingValues,
  })
  const { trigger, getValues, reset } = methods
  const { enrollmentInfo, setEnrollmentInfo } = useContext(AddMemberContext)
  const { setStepHandler, setIsError } = useContext(AddMemberProgressContext)

  useEffect(() => {
    const onClickNext = async () => {
      const isValid = await trigger()
      if (!isValid) {
        setIsError(true)
      }
      if (isValid) {
        setIsError(false)
        const { billingLocation } = getValues()

        setEnrollmentInfo((enrollmentInfo) => ({
          ...enrollmentInfo,
          billingInfo: { billingLocation },
        }))
      }
      return isValid
    }
    setStepHandler({ onClickNext })
  }, [getValues, trigger, setEnrollmentInfo, setStepHandler, setIsError])

  useEffect(() => {
    if (enrollmentInfo) {
      reset({ ...enrollmentInfo.billingInfo })
    }
  }, [enrollmentInfo, reset])

  return (
    <>
      <FormProvider {...methods}>
        <div className="col-lg-6 col-sm-6 col-xs-12">
          <BillingLocation />
        </div>
      </FormProvider>
    </>
  )
}
