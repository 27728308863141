import { Link, Heading } from '@component-library/helios'
import { useContext, useEffect } from 'react'
import { FormProvider, useForm, type UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { DependentRelationship } from '../../../../types/enums'
import { AddMemberContext, type AddMemberContextData } from '../../../context/AddMemberContext'
import { AddMemberProgressContext } from '../../../context/AddMemberProgressContext'
import { DataDetails } from '../../../uiComponents/DataDetails/DataDetails'
import { Stack } from '../../../uiComponents/Stack/Stack'
import { formatDateFullMonthWithYear, formatFullName, formatSSN, getAge } from '../../../utils/format'
import { DependentsTable } from '../Dependents/DependentsTable'
import { type AddMemberStepId } from '../hooks'

import { TermsConditions } from './TermsConditions'

type ReviewProps = {
  onStepChange: (step: AddMemberStepId) => void
}

const formatAddress = (mailingAddress: AddMemberContextData['enrollmentInfo']['mailingAddress']) =>
  [
    mailingAddress.address,
    mailingAddress.address2,
    mailingAddress.city,
    mailingAddress.state?.value,
    mailingAddress.zip,
    mailingAddress.country?.label,
  ]
    .filter(Boolean)
    .join(', ')

export const Review = ({ onStepChange }: ReviewProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'Review' })
  const methods: UseFormReturn = useForm({
    mode: 'onChange',
  })
  const { trigger, reset } = methods
  const { setStepHandler, setIsError } = useContext(AddMemberProgressContext)
  const { enrollmentInfo } = useContext(AddMemberContext)

  useEffect(() => {
    const onClickNext = async () => {
      const isValid = await trigger()
      if (!isValid) {
        setIsError(true)
      }
      if (isValid) {
        setIsError(false)
      }
      return isValid
    }
    setStepHandler({ onClickNext })
  }, [trigger, setStepHandler, setIsError])

  const sopuse = enrollmentInfo.dependentInfo.filter(
    (dependent) => dependent.relationship === DependentRelationship.SPOUSE,
  )
  const children = enrollmentInfo.dependentInfo.filter(
    (dependent) => dependent.relationship === DependentRelationship.DEPENDENT,
  )

  return (
    <FormProvider {...methods}>
      <div className="col-lg-12 col-sm-12 col-xs-12">
        <p className="mb-4">{t('REVIEW_TXT')}</p>
        <div className="mb-5">
          <Stack direction="row" growFirst>
            <Heading variant="3" className="mb-3">
              {t('MEMBER_INFORMATION')}
            </Heading>
            <Link onClick={() => onStepChange('member_info')}>Edit</Link>
          </Stack>
          <DataDetails
            data={[
              { label: 'Name', value: formatFullName(enrollmentInfo.personalInfo) },
              {
                label: 'Date of birth',
                value: enrollmentInfo.personalInfo.dateOfBirth?.selected
                  ? formatDateFullMonthWithYear(enrollmentInfo.personalInfo.dateOfBirth.selected) +
                    ` (${t('YEARS_OLD', { count: getAge(enrollmentInfo.personalInfo.dateOfBirth.selected) })})`
                  : null,
              },
              { label: 'Gender', value: t('MEMBERGENDER.' + enrollmentInfo.personalInfo.genderCode) },
              { label: 'SSN', value: formatSSN(enrollmentInfo.personalInfo.ssn) },
              { label: 'Address', value: formatAddress(enrollmentInfo.mailingAddress) },
              { label: 'Email', value: enrollmentInfo.contactInfo.email },
              {
                label: 'Phone',
                value: enrollmentInfo.contactInfo.phoneNum
                  ? `${enrollmentInfo.contactInfo.phoneNum} (${enrollmentInfo.contactInfo.phoneType?.label})`
                  : null,
              },
            ]}
          />
        </div>
        <div className="mb-5">
          <Stack direction="row" growFirst>
            <Heading variant="3" className="mb-3">
              {t('EMPLOYMENT')}
            </Heading>
            <Link onClick={() => onStepChange('employment')}>Edit</Link>
          </Stack>
          <DataDetails
            data={[
              {
                label: enrollmentInfo.employmentInfo.hireDateCode + ' hire date',
                value: enrollmentInfo.employmentInfo.hireDate?.selected
                  ? formatDateFullMonthWithYear(enrollmentInfo.employmentInfo.hireDate.selected)
                  : null,
              },
              { label: 'Eligible employee group', value: enrollmentInfo.employmentInfo.eligibleEmpGrp?.label },
              {
                label: 'Signature date',
                value: enrollmentInfo.employmentInfo.signatureDate?.selected
                  ? formatDateFullMonthWithYear(enrollmentInfo.employmentInfo.signatureDate?.selected)
                  : null,
              },
            ]}
          />
        </div>
        <div className="mb-5">
          <Stack direction="row" growFirst>
            <Heading variant="3" className="mb-3">
              {t('DEPENDENTS')}
            </Heading>
            <Link onClick={() => onStepChange('dependents')}>Edit</Link>
          </Stack>
          {sopuse.length > 0 && (
            <div className="mb-3">
              <p>Spouse / domestic partner</p>
              <DependentsTable
                dependents={enrollmentInfo.dependentInfo.filter(
                  (dependent) => dependent.relationship === DependentRelationship.SPOUSE,
                )}
              />
            </div>
          )}
          {children.length > 0 && (
            <div>
              <p>Children</p>
              <DependentsTable
                dependents={enrollmentInfo.dependentInfo.filter(
                  (dependent) => dependent.relationship === DependentRelationship.DEPENDENT,
                )}
              />
            </div>
          )}
        </div>
        <div className="mb-5">
          <Stack direction="row" growFirst>
            <Heading variant="3" className="mb-3">
              {t('BENEFITS')}
            </Heading>
            <Link onClick={() => onStepChange('benefits')}>Edit</Link>
          </Stack>
        </div>
        <Heading variant="3">{t('TERMS_CONDITIONS')}</Heading>
        <TermsConditions />
      </div>
    </FormProvider>
  )
}
