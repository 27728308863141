import { Notification } from '@component-library/helios'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import * as styles from './ErrorNotification.module.css'

interface ErrorNotificationProps {
  errorMsg?: string
}

export const ErrorNotification: FC<ErrorNotificationProps> = ({ errorMsg }: ErrorNotificationProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'AddMember' })
  return (
    <div className={styles.errorNotification}>
      <Notification variant="danger">
        <>{errorMsg ? errorMsg : t('ERROR_NOTIF')}</>
      </Notification>
    </div>
  )
}
