import { type PropsWithChildren } from 'react'

type ScrollableContainerProps = {
  width: string
}

/* This component allows the cildren to be scrolled within a fixed container */
export const ScrollableContainer = (props: PropsWithChildren<ScrollableContainerProps>) => {
  return (
    <div style={{ overflowX: 'auto' }}>
      <div style={{ minWidth: props.width }}>{props.children}</div>
    </div>
  )
}
