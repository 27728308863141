.dot {
  height: 9px;
  width: 9px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.5rem;
}

.color-APPROVED_ACTIVE_SOON {
  background-color: var(--orange-600);
}

.color-APPROVED_ACTIVE {
  background-color: var(--green-600);
}

.color-TERMINATING_SOON {
  background-color: var(--red-600);
}

.color-PENDING {
  background-color: var(--orange-600);
}
