import type { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { MainLayout } from './components/MainLayout/MainLayout'
import { Logout } from './pages/Logout'
import { AddMember } from './pages/member/add-member/AddMember'
import { DentalId } from './pages/member/dental-ID/DentalId'
import { MemberDetail } from './pages/member/detail/MemberDetail'
import { MemberList } from './pages/member/list/MemberList'

export const AppRoutes: FC = () => (
  <Routes>
    <Route element={<MainLayout />}>
      {/* Member list pages */}
      <Route path="/members" element={<MemberList />} />
      {/* Member detail pages */}
      <Route path="/member/:memberId/*" element={<MemberDetail />} />
      <Route path="/member/:memberId/dental-id" element={<DentalId />} />
      {/* Add member pages */}
      <Route path="/add-member" element={<AddMember />} />
      {/* Logout pages */}
      <Route path="/logout" element={<Logout />} />
      {/* Default root */}
      <Route path="/" element={<Navigate replace to="/members" />} />
    </Route>
  </Routes>
)
