import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { BrowserRouter } from 'react-router-dom'

import '../i18n/i18n'
import './App.module.css'
import { AppRoutes } from './AppRoutes'
import { getQueryClient } from './api/queryClient'
import { AuthProvider } from './components/Auth/AuthProvider'
import { QualtricsWebsiteFeedback } from './components/ClientSurvey/Qualtrics'
import { ViewTracker } from './components/ViewTracker/ViewTracker'

const QueryClient: React.FC<React.PropsWithChildren> = ({ children }) => {
  const queryClient = getQueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      {children}
    </QueryClientProvider>
  )
}

export const App = () => {
  return (
    <BrowserRouter>
      <QualtricsWebsiteFeedback />
      <QueryClient>
        <AuthProvider>
          <ViewTracker />
          <AppRoutes />
        </AuthProvider>
      </QueryClient>
    </BrowserRouter>
  )
}
