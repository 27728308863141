import { HelperText, Checkbox, Dropdown, Body } from '@component-library/helios'
import { type FC, useState, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import type { CoverageInfo, TierInfo } from 'types/domain'

import { currencyFormatter } from '../../../utils/format'

interface VolumeBasedCoverageProps {
  label?: string
  isViewOnly?: boolean
  helperText?: string
  isRequired?: boolean
  tiers?: TierInfo[]
}

export const VolumeBasedCoverage: FC<VolumeBasedCoverageProps> = ({
  isViewOnly = false,
  isRequired = true,
  tiers,
}: VolumeBasedCoverageProps) => {
  const { setValue, getValues, trigger } = useFormContext()
  const { control } = useFormContext()
  const { t } = useTranslation('translation', { keyPrefix: 'Benefits' })
  const [selected, setSelected] = useState('')

  useEffect(() => {
    // setSelected(enrollmentInfo?.mailingAddress?.state?.value)
  }, [setSelected])

  const onCoverageChange = ({ index, option }: { index: number; option: { label: string; value: string } }) => {
    setSelected(option.value)
    setValue('state', option)
    trigger('state')
  }

  return (
    <>
      <div data-cy="radio_tiered_coverage_group" data-testid="tieredCoverage">
        <Controller
          control={control}
          name="volumeBasedCoverage"
          rules={{
            required: {
              value: isRequired,
              message: t('PLEASE_MAKE_SELECTION'),
            },
          }}
          render={({ field, fieldState }) => {
            const { ...fields } = field
            //  if (electedTier && !field.value) field.value = electedTier
            return (
              <>
                <div className="mt-3 mb-4">
                  {tiers?.map((tier) => (
                    <>
                      <Body variant="medium-bold">{t(tier.tier)} </Body>

                      {tier.coverages.map((coverage: CoverageInfo) =>
                        coverage.benefitAmounts.length == 1 ? (
                          <Checkbox
                            {...fields}
                            id="volume-based-coverage"
                            label={`${coverage.name} (${currencyFormatter(coverage.benefitAmounts[0])})`}
                            value={coverage.benefitAmounts[0]}
                            name="volumeBasedCoverage"
                            isInvalid={fieldState.invalid}
                            checked={field.value === coverage.benefitAmounts[0]}
                            data-cy="volume-based-coverage"
                            key={1}
                            disabled={isViewOnly}
                            inline={false}
                          />
                        ) : (
                          <div className="mt-3 mb-3 col-lg-6 col-sm-6 col-xs-12" key={coverage?.name}>
                            <Dropdown
                              {...fields}
                              id={tier + field.name}
                              label={coverage.name}
                              selected={selected}
                              error={fieldState?.invalid}
                              helperText={fieldState.error?.message}
                              inputProps={undefined}
                              inputRef={undefined}
                              onChange={onCoverageChange}
                              options={coverage.benefitAmounts.map((amount) => ({
                                label: amount,
                                value: amount,
                              }))}
                              placeholder=""
                            />
                          </div>
                        ),
                      )}
                    </>
                  ))}
                </div>
                {fieldState.invalid && (
                  <div>
                    <HelperText id={`gender-error`} error>
                      {fieldState.error?.message}
                    </HelperText>
                  </div>
                )}
              </>
            )
          }}
        />
      </div>
    </>
  )
}
