import { useEffect } from 'react'

import { getConfigForQualtricsCookieName, getConfigForQualtricsUrl } from '../../utils/getClientConfig'

export const QualtricsWebsiteFeedback = () => {
  useEffect(() => {
    // Function to create the Qualtrics script
    const createQualtricsScript = () => {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = getConfigForQualtricsUrl()
      script.async = true
      document.body.appendChild(script)
    }

    // Function to set the Qualtrics cookie
    const setQualtricsVisitorCookie = () => {
      const cookieName = getConfigForQualtricsCookieName()
      const cookieValue = 'r:100:0'
      const expirationDate = new Date()
      expirationDate.setTime(expirationDate.getTime() + 6048e5) // 7 days
      document.cookie = `${cookieName}=${cookieValue}; expires=${expirationDate.toUTCString()}; path=/`
    }

    // Create the Qualtrics script and set the visitor cookie
    createQualtricsScript()
    setQualtricsVisitorCookie()
  }, [])

  return <div id="ZN_cvXOX4Pk2Xu0mRU">{/* This div is where the Qualtrics feedback widget will be inserted */}</div>
}
