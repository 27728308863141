.dropdownIcon {
  padding: 4px !important;
  width: 1.5rem;
  justify-content: right;
  margin-left: auto;
}

.dropdownIcon .dropdownLabel {
  display: none;
}

.memberListTable td {
  vertical-align: top;
}

.memberName a {
  white-space: initial;
}
