import { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'

import { usePostExtendAuth } from '../../api/hooks/usePostExtendAuth'
import { useAuth } from '../../hooks/useAuth'
import { getAuthTokenActivity } from '../../utils/getAuthTokenActivity'

import { ExtendSessionModal } from './ExtendSessionModal'

export const AuthMonitor = () => {
  const { logout, authToken } = useAuth()
  const [isExtendSessionModalOpen, setIsExtendSessionModalOpen] = useState(false)
  const [activity, setActivity] = useState(getAuthTokenActivity(authToken))
  const extendAuth = usePostExtendAuth({ body: { token: authToken?.token } }, { enabled: false })
  const idleTimer = useIdleTimer({
    crossTab: true,
    timeout: 2 * 60 * 1000, // 2 minutes.
  })

  const { refetch: refetchExtendAuth } = extendAuth

  // Update token activity.
  useEffect(() => {
    const intervalId = setInterval(() => {
      setActivity(getAuthTokenActivity(authToken))
    }, 1000) // Each second, to be able to render the remaining time in a timely fashion.

    return () => {
      clearInterval(intervalId)
    }
  }, [authToken])

  // Handle session activity
  const isIdle = idleTimer.isIdle()
  useEffect(() => {
    // Close the modal if the re-authorization fails.
    if (extendAuth.isError) {
      setIsExtendSessionModalOpen(false)
    } else if (activity.status === 'will-expire' && authToken?.token && !isExtendSessionModalOpen) {
      if (isIdle) {
        // Open the modal if the session is about to expire and the user is idle.
        setIsExtendSessionModalOpen(true)
      } else if (!extendAuth.isLoading) {
        // Extend the session if the user is not idle.
        refetchExtendAuth()
      }
    } else if (activity.status === 'active' && isExtendSessionModalOpen) {
      // Close the modal if the session is active.
      setIsExtendSessionModalOpen(false)
    } else if (activity.status === 'expired') {
      // Logout if the session is expired.
      logout()
    }
  }, [
    activity.status,
    isExtendSessionModalOpen,
    logout,
    authToken?.token,
    extendAuth.isError,
    isIdle,
    refetchExtendAuth,
    extendAuth.isLoading,
  ])

  // Handle the re-authorization (session extension).
  useEffect(() => {
    if (extendAuth.isSuccess) {
      const { token, createdAt } = extendAuth.data.user
      const newToken = { token, createdAt }
      setActivity(getAuthTokenActivity(newToken))
    }
  }, [extendAuth.data?.user, extendAuth.isSuccess])

  const handleExtendSession = () => {
    refetchExtendAuth()
  }

  const handleLogout = () => {
    logout()
  }

  return (
    <ExtendSessionModal
      isModalOpen={isExtendSessionModalOpen}
      isLoading={extendAuth.isLoading}
      remainingTime={activity.remainingTime}
      onExtend={handleExtendSession}
      onLogout={handleLogout}
    />
  )
}
