import { Fragment, type ReactNode } from 'react'

import * as styles from './DataDetails.module.css'

type DataDetailsProps = {
  data: Array<{ label: string; value: ReactNode }>
}
export const DataDetails = ({ data }: DataDetailsProps) => (
  <dl className={styles.dataDetails}>
    {data.map((row) => (
      <Fragment key={row.label}>
        <dt>{row.label}</dt>
        <dd>{row.value || '-'}</dd>
      </Fragment>
    ))}
  </dl>
)
