import { Badge } from '@component-library/helios'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { BenefitStatus } from '../../../../types/enums'

type BenefitStatusProps = {
  status: string
}

export const BenefitStatusBadge: FC<BenefitStatusProps> = ({ status }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'MemberDetail' })

  return (
    <Badge
      label={t(status, { keyPrefix: 'BenefitStatus' })}
      variant={
        status === BenefitStatus.APPROVED_ACTIVE
          ? 'success'
          : status === BenefitStatus.TERMINATING_SOON
          ? 'error'
          : 'warning'
      }
    />
  )
}
