import { Radio, HelperText } from '@component-library/helios'
import { useContext, type FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import type { TierInfo } from 'types/domain'

import { AddMemberContext } from '../../../context/AddMemberContext'

import * as styles from './Benefits.module.css'

interface TieredCoverageProps {
  label?: string
  isViewOnly?: boolean
  helperText?: string
  isRequired?: boolean
  tiers?: TierInfo[]
  electedTier?: string
}

export const TieredCoverage: FC<TieredCoverageProps> = ({
  isViewOnly = false,
  isRequired = true,
  tiers,
  electedTier,
}: TieredCoverageProps) => {
  const { control } = useFormContext()
  const { enrollmentInfo } = useContext(AddMemberContext)
  const memberName = enrollmentInfo?.personalInfo.firstName
  const spouseName = enrollmentInfo?.dependentInfo.filter((dependent) => dependent.relationship === 'SPOUSE')[0]
    ?.firstName
  const childrenNames = enrollmentInfo?.dependentInfo
    .filter((dependent) => dependent.relationship === 'DEPENDENT')
    .map((dependent) => dependent.firstName)
    .join(', ')
  const getCoveredText = (tier: TierInfo) => {
    const coveredText = `Covers ${memberName} ${
      tier.covers.includes('SPOUSE') ? ', Spouse / domestic partner ' + (spouseName ? '(' + spouseName + ')' : '') : ''
    }, and unlimited children ${childrenNames ? '(' + childrenNames + ')' : ''}.`
    return tier.covers.includes('CHILDREN') ? coveredText : ''
  }
  // , ${tier.covers.contains('spouse') / domestic partner${
  //   spouseName ? spouseName : ''
  // }, and unlimited children ${childrenNames ? '(' + childrenNames + ')' : ''}.`)
  const { t } = useTranslation('translation', { keyPrefix: 'Benefits' })

  return (
    <>
      <div data-cy="radio_tiered_coverage_group" data-testid="tieredCoverage">
        <Controller
          control={control}
          name="electedTier"
          rules={{
            required: {
              value: isRequired,
              message: t('PLEASE_MAKE_SELECTION'),
            },
          }}
          render={({ field, fieldState }) => {
            const { ...fields } = field
            if (electedTier && !field.value) field.value = electedTier
            return (
              <>
                <div>
                  {tiers?.map((tier) => (
                    <>
                      <Radio
                        {...fields}
                        id={tier.benefitId}
                        label={t(tier.tier)}
                        value={tier.tier}
                        name="electedTier"
                        isInvalid={fieldState.invalid}
                        checked={field.value === tier.tier}
                        data-cy={'radio_tier_' + tier.tier}
                        key={tier.tier}
                        disabled={isViewOnly}
                      />
                      {tier.covers && <span className={styles.radioCaption}>{getCoveredText(tier)}</span>}
                    </>
                  ))}

                  {/* <Radio
                    {...fields}
                    id="tier-radio-employee_spouse"
                    label={t('EMPLOYEE_SPOUSE')}
                    value="EMPLOYEE_SPOUSE"
                    name="tier"
                    isInvalid={fieldState.invalid}
                    checked={field.value === 'EMPLOYEE_SPOUSE'}
                    data-cy="radio_tier_employee_spouse"
                    key={2}
                    disabled={isViewOnly}
                  />
                  {/* <span className={styles.radioCaption}>
                    Covers {employeeName} and {spouseName ? ` (${spouseName}).` : 'spouse / domestic partner.'}
                  </span> */}
                  {/* <Radio
                    {...fields}
                    id="tier-radio-employee_child"
                    label={t('EMPLOYEE_CHILD')}
                    value="EMPLOYEE_CHILD"
                    name="tier"
                    isInvalid={fieldState.invalid}
                    checked={field.value === 'EMPLOYEE_CHILD'}
                    data-cy="radio_tier_employee_child"
                    key={3}
                    disabled={isViewOnly}
                  />
                  <span className={styles.radioCaption}>
                    Covers {memberName}, and unlimited children{childrenNames ? ` (${childrenNames})` : ''}
                  </span>
                  <Radio
                    {...fields}
                    id="tier-radio-employee_family"
                    label={t('EMPLOYEE_FAMILY')}
                    value="EMPLOYEE_FAMILY"
                    name="tier"
                    isInvalid={fieldState.invalid}
                    checked={field.value === 'EMPLOYEE_FAMILY'}
                    data-cy="radio_tier_employee_family"
                    key={4}
                    disabled={isViewOnly}
                  />
                   */}
                </div>
                {fieldState.invalid && (
                  <div>
                    <HelperText id={`gender-error`} error>
                      {fieldState.error?.message}
                    </HelperText>
                  </div>
                )}
              </>
            )
          }}
        />
      </div>
    </>
  )
}
