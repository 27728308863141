.dataDetails {
  display: grid;
  grid-template-columns: 200px auto;

  dt {
    color: var(--grey-700);
    font-weight: normal;
    padding-right: 0.5rem;
    grid-column: 1;
  }
  dd {
    grid-column: 2;
    margin-bottom: 0.25rem;
  }
}
