import { Dropdown } from '@component-library/helios'
import { type FC, useState, useEffect, useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { states, provinces } from '../../../../common/mockStates'
import { AddMemberContext } from '../../../../context/AddMemberContext'
import * as styles from '../PersonalInfo/PersonalInfo.module.css'

type StateProps = {
  isCanada: boolean
}

export const State: FC<StateProps> = ({ isCanada }: StateProps) => {
  const { control, setValue, getValues, trigger } = useFormContext()
  const { t } = useTranslation('translation', { keyPrefix: 'PersonalInfo' })
  const { enrollmentInfo } = useContext(AddMemberContext)
  const [selected, setSelected] = useState<string | undefined>()

  useEffect(() => {
    setSelected(enrollmentInfo?.mailingAddress?.state?.value)
  }, [setSelected, enrollmentInfo])

  const onStateChange = ({ index, option }: { index: number; option: { label: string; value: string } }) => {
    setSelected(option.value)
    setValue('state', option)
    trigger('state')
  }

  const validateState = ({
    index,
    option,
  }: {
    index: number
    option: { label: string; value: string }
  }): true | string => {
    if (getValues('state').value === '') {
      return t('REQUIRED')
    }
    return true
  }

  return (
    <>
      <div className={styles.textBoxMargin + ' ' + styles.halfRow} data-testid="state">
        <Controller
          rules={{
            required: {
              value: true,
              message: t('REQUIRED'),
            },
            validate: validateState,
          }}
          control={control}
          name="state"
          render={({ field, fieldState }) => {
            const { ...fields } = field
            return (
              <Dropdown
                {...fields}
                id={field.name}
                label={isCanada ? t('TERRITORY') : t('STATE')}
                selected={selected}
                error={fieldState?.invalid}
                helperText={fieldState.error?.message}
                inputProps={undefined}
                inputRef={undefined}
                onChange={onStateChange}
                options={
                  isCanada
                    ? provinces?.map((province) => ({
                        label: province.label,
                        value: province.value,
                      }))
                    : states?.map((state) => ({
                        label: state.label,
                        value: state.value,
                      }))
                }
                placeholder=""
              />
            )
          }}
        />
      </div>
    </>
  )
}
