import { useCallback, useState } from 'react'
import type { Member_ListRequestQueryParams, SortingQueryParams } from 'types/domain'

interface Pagination {
  page: number
  pageSize: number
}
// because the query expects strings
interface QueryPagination {
  page: string
  pageSize: string
}

export const useMemberListPagination = (initPagination: Member_ListRequestQueryParams) => {
  const [pagination, setPagination] = useState<Pagination>({
    page: Number(initPagination.page),
    pageSize: Number(initPagination.pageSize),
  })
  const [sort, setSort] = useState<SortingQueryParams>({
    sortKey: initPagination.sortKey,
    sortDirection: initPagination.sortDirection,
  })

  const handleSortChange = useCallback((sort: SortingQueryParams) => {
    setSort(sort)
  }, [])

  const handlePageChange = useCallback((page: number) => {
    setPagination((prev) => ({ page, pageSize: prev?.pageSize ?? 25 }))
  }, [])

  const handleRowsPerPageChange = useCallback((pageSize: number) => {
    setPagination((prev) => ({ page: prev?.page ?? 1, pageSize }))
  }, [])

  const convertPaginationToString = useCallback(
    (pagination: Pagination): QueryPagination => ({
      page: String(pagination?.page ?? 1),
      pageSize: String(pagination?.pageSize ?? 25),
    }),
    [],
  )

  return {
    sort,
    pagination: convertPaginationToString(pagination),
    onPageChange: handlePageChange,
    onRowsPerPageChange: handleRowsPerPageChange,
    onSortChange: handleSortChange,
  }
}
