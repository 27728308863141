/* eslint-disable no-console */
import { getAnalyticsBreadcrumbs } from './formatAnalyticsBreadcrumbs'
import { isLocal, isProduction } from './getClientConfig'
import { getPageMeta } from './pageMeta'

/**
 * @see https://docs.tealium.com/platforms/javascript/single-page-applications/#track-views
 */
export const trackView = (data: Record<string, string | string[]>) => {
  if (!window.utag) {
    return console.warn('utag is not defined')
  }
  //track custom events to tealium only when user is set
  if (isLocal || isProduction || !window.utag_data.user_id || window.utag_data.user_id === 'anonymous') {
    return console.debug('skipping utag.view because current is local', data)
  }

  window.utag.view(data)
}

/**
 * @see https://docs.tealium.com/platforms/javascript/single-page-applications/#track-events
 */
export const trackEvent = (data: Record<string, string | string[]>) => {
  if (!window.utag) {
    return console.warn('utag is not defined')
  }

  //track links to tealium only when user is set
  if (isLocal || isProduction || !window.utag_data.user_id || window.utag_data.user_id === 'anonymous') {
    return console.debug('skipping utag.link because current is local', data)
  }

  const meta = getPageMeta(location.pathname)
  const utagdata = {
    page_canonical_url: window.location.origin + location.pathname,
    page_breadcrumb: getAnalyticsBreadcrumbs(location.pathname),
    page_language: 'en',
    page_category: meta?.category ?? 'Uncategorized',
    page_subcategory: meta?.subCategory ?? 'Uncategorized',
  }

  const utageventdata = { ...data, ...utagdata }
  window.utag.link(utageventdata)
}
