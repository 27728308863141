import { DateTime, FixedOffsetZone } from 'luxon'
import type { FacilityView } from 'types/domain'

import { MemberSmoker } from '../../types/enums'

const uiDateWithYear = 'LLLL d, y'

/**
 * @param input - (localized) Date or ISO date string from API (UTC timezone)
 * @returns DateTime object
 */
const toDateTime = (input: Date | string) =>
  typeof input === 'string'
    ? DateTime.fromISO(input, { zone: FixedOffsetZone.utcInstance })
    : DateTime.fromJSDate(input)

/**
 * @param input
 * @returns e.g. October 31, 2021
 */
export const formatDateFullMonthWithYear = (input: Date | string) => toDateTime(input).toFormat(uiDateWithYear)

/**
 * @param input
 * @returns e.g. Oct 31, 2021
 */
export const formatDateShortMonthWithYear = (input: Date | string) =>
  toDateTime(input).toLocaleString({
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })

export const getAge = (dateOfBirth: Date | string) => -Math.ceil(toDateTime(dateOfBirth).diffNow('years').years)

export type ListFormatOptions = {
  type: 'conjunction' | 'disjunction' | 'unit'
  style: 'long' | 'short' | 'narrow'
}

const defaultListFormat: ListFormatOptions = {
  type: 'conjunction',
  style: 'long',
}

export function listFormat(wordArray: string[], options: ListFormatOptions = defaultListFormat) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- ListFormat is not currently exported because Microsoft is slacking
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- see above
  return new Intl.ListFormat('en-US', options).format(wordArray)
}

/** Format currency
 *
 * @param input - string or number to be formated as currency
 * @returns formatted currency string which will be in USD currency format with maximum of 2 decimal places
 * if decimals in input, else 0 decimal places
 **/
export const currencyFormatter = (input: string | number) => {
  const containsDecimal = Number(input) % 1 !== 0
  const formattedNumber = containsDecimal
    ? new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(Number(input))
    : new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      }).format(Number(input))

  return formattedNumber
}

export const smokerFormatter = (input: string) => {
  switch (true) {
    case input === MemberSmoker.SMOKER: {
      return 'Yes'
    }
    case input === MemberSmoker.NON_SMOKER: {
      return 'No'
    }
    default:
      return '—'
  }
}

export const facilityIDFormatter = (input: FacilityView[]) => {
  let memberFacilityID = ''
  if (input && input.length) {
    memberFacilityID = input
      .map((facility) => facility.facilityId)
      .filter((facilityAttrib) => facilityAttrib)
      .join(', ')
  }

  return memberFacilityID
}

//Format SSN
export const formatSSN = (value: string | number | string[] | undefined): string => {
  if (typeof value === 'string') {
    if (value) {
      const newValue = value.replace(/-/g, '')
      if (newValue.length >= 5) {
        return newValue.substring(0, 3) + '-' + newValue.substring(3, 5) + '-' + newValue.substring(5)
      } else if (newValue.length >= 3) {
        return newValue.substring(0, 3) + '-' + newValue.substring(3)
      } else {
        return newValue
      }
    }
    return ''
  } else if (Array.isArray(value)) {
    return value.map((item) => formatSSN(item)).join(', ')
  } else if (typeof value === 'number') {
    return formatSSN(value.toString())
  } else {
    return ''
  }
}

// Format full name
export const formatFullName = ({
  firstName,
  middleName,
  lastName,
  suffix,
}: {
  firstName: string
  middleName: string
  lastName: string
  suffix: string
}): string => [firstName, middleName, lastName, suffix].filter(Boolean).join(' ')
