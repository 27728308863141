.depTable {
  margin-top: 1.5rem;
}

.noDependents {
  display: block;
  text-align: center;
  white-space: pre-wrap;
  h4 {
    display: none;
  }
}
