import { TextField } from '@component-library/helios'
import type { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { SSN_REGEX } from '../../../../common/enums/regex'

import * as styles from './PersonalInfo.module.css'

interface SSNProps {
  optionalText?: string
}

export const Ssn: FC<SSNProps> = ({ optionalText = 'Optional' }: SSNProps) => {
  const { control } = useFormContext()
  const { t } = useTranslation('translation', { keyPrefix: 'PersonalInfo' })

  return (
    <>
      <div className={styles.textBoxMargin} data-testid="ssn">
        <Controller
          render={({ field, fieldState }) => {
            const { onChange, ...fields } = field
            return (
              <TextField
                {...fields}
                id={field.name}
                label={t('SSN')}
                error={fieldState.invalid}
                helperText={fieldState.error ? fieldState.error?.message : optionalText}
                maxLength={9}
                minLength={9}
                value={field.value}
                onChange={(e) => {
                  onChange(e)
                }}
              />
            )
          }}
          rules={{
            required: {
              value: false,
              message: '',
            },
            pattern: {
              value: SSN_REGEX,
              message: t('INVALID_SSN'),
            },
          }}
          control={control}
          name="ssn"
        />
      </div>
    </>
  )
}
