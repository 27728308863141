import axios from 'axios'

import { formatUrl } from './formatUrl'

export const instance = axios.create({ baseURL: '/' })

// Required to send cookies on each request
instance.defaults.withCredentials = true

// Interceptors
instance.interceptors.response.use(
  function onFullfilled(response) {
    return response
  },
  function onRejected(error: unknown) {
    return Promise.reject(error)
  },
)

export const createEndpointUtils = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Params extends Record<string, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Query extends Record<string, any>,
  Body = unknown,
  Response = unknown,
>(
  method: string,
  path: string,
) => {
  async function apiRequest({ params, query, body }: { params?: Params; query?: Query; body?: Body }) {
    const response = await instance.request({
      method,
      url: formatUrl(path, params, query),
      data: body,
    })
    return response.data as Response
  }

  function apiUrl({ params, query }: { params?: Params; query?: Query }) {
    return formatUrl(path, params, query)
  }

  return {
    createRequestHook: <R, M>(
      fn: (request: typeof apiRequest, options: { params?: Params; query?: Query; body?: Body }, ...more: M[]) => R,
    ) => {
      return function hook({ params, query, body }: { params?: Params; query?: Query; body?: Body }, ...more: M[]) {
        return fn(apiRequest, { params, query, body }, ...more)
      }
    },
    createUrlHook: <R, M>(
      fn: (url: typeof apiUrl, options: { params?: Params; query?: Query; body?: Body }, ...more: M[]) => R,
    ) => {
      return function hook() {
        return function ({ params, query, body }: { params?: Params; query?: Query; body?: Body }, ...more: M[]) {
          return fn(apiUrl, { params, query, body }, ...more)
        }
      }
    },
    getApiRequest: () => prepareApiRequest<Params, Query, Body, Response>(method, path),
  }
}

/**
 * Return a function that wrap an API call for a given method and path.
 */
const prepareApiRequest = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Params extends Record<string, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Query extends Record<string, any>,
  Body = unknown,
  Response = unknown,
>(
  method: string,
  path: string,
) => {
  return async function apiRequest({ params, query, body }: { params?: Params; query?: Query; body?: Body }) {
    const response = await instance.request({
      method,
      url: formatUrl(path, params, query),
      data: body,
    })
    return response.data as Response
  }
}
