import { createContext, type Dispatch, type SetStateAction } from 'react'

interface AddMemberProgressContextType {
  stepHandler: { onClickNext: () => Promise<boolean> }
  setStepHandler: Dispatch<SetStateAction<{ onClickNext: () => Promise<boolean> }>>
  isError: boolean
  setIsError: Dispatch<SetStateAction<boolean>>
  errorMsg: string
  setErrorMsg: Dispatch<SetStateAction<string>>
}

export const AddMemberProgressContext = createContext<AddMemberProgressContextType>({
  stepHandler: {
    onClickNext: async () => true,
  },
  setStepHandler: () => {},
  isError: false,
  setIsError: () => {},
  errorMsg: '',
  setErrorMsg: () => {},
})
