import { Radio, HelperText } from '@component-library/helios'
import type { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import * as styles from './PersonalInfo.module.css'

interface GenderProps {
  label?: string
  isViewOnly?: boolean
  helperText?: string
  isRequired?: boolean
  inline?: boolean
}

export const Gender: FC<GenderProps> = ({ isViewOnly = false, isRequired = true, inline = false }: GenderProps) => {
  const { control } = useFormContext()
  const { t } = useTranslation('translation', { keyPrefix: 'PersonalInfo' })

  return (
    <>
      <div className={styles.textBoxMargin} data-cy="radio_gender_group">
        <h5>{t('GENDER')}</h5>
        <Controller
          control={control}
          name="genderCode"
          rules={{
            required: {
              value: isRequired,
              message: t('PLEASE_MAKE_SELECTION'),
            },
          }}
          render={({ field, fieldState }) => {
            const { ...fields } = field
            return (
              <>
                <div>
                  <Radio
                    {...fields}
                    id="gender-radio-female"
                    label={t('FEMALE')}
                    value="FEMALE"
                    name="genderCode"
                    isInvalid={fieldState.invalid}
                    checked={field.value === 'FEMALE'}
                    data-cy="radio_gender_female"
                    key={1}
                    disabled={isViewOnly}
                    inline={inline}
                  />
                  <Radio
                    {...fields}
                    id="gender-radio-male"
                    label={t('MALE')}
                    value="MALE"
                    name="genderCode"
                    isInvalid={fieldState.invalid}
                    checked={field.value === 'MALE'}
                    data-cy="radio_gender_male"
                    key={2}
                    disabled={isViewOnly}
                    inline={inline}
                  />
                  <Radio
                    {...fields}
                    id="gender-radio-prefer-not-to-say"
                    label={t('PREF_NOT_SAY')}
                    value="UNIDENTIFIED"
                    name="genderCode"
                    isInvalid={fieldState.invalid}
                    checked={field.value === 'UNIDENTIFIED'}
                    data-cy="radio_gender_prefer_not_to_say"
                    key={3}
                    disabled={isViewOnly}
                    inline={inline}
                  />
                </div>
                {fieldState.invalid && (
                  <div>
                    <HelperText id={`gender-error`} error>
                      {fieldState.error?.message}
                    </HelperText>
                  </div>
                )}
              </>
            )
          }}
        />
      </div>
    </>
  )
}
