import { DigitalIdCard } from '@sunlife/slus-domain-components'
import type { FC } from 'react'
import { useParams } from 'react-router-dom'

import { useGetDentalIDCard } from '../../../api/hooks/useGetDentalIDCard'
import { QueryResult } from '../../../components/QueryResult/QueryResult'

export const DentalId: FC = () => {
  const { memberId } = useParams()
  const dentalIdCard = useGetDentalIDCard({ params: { memberId: memberId } }, { enabled: typeof memberId === 'string' })

  return <QueryResult query={dentalIdCard}>{(data) => <DigitalIdCard data={data.memberIDCard} />}</QueryResult>
}
