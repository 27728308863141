import { Dropdown } from '@component-library/helios'
import { type FC, useEffect, useState, useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { cities } from '../../../common/mockBillLocations'
import { AddMemberContext } from '../../../context/AddMemberContext'

import * as styles from './Billing.module.css'

export const BillingLocation: FC = () => {
  const { control, setValue, trigger } = useFormContext()
  const { t } = useTranslation('translation', { keyPrefix: 'Billing' })
  const { enrollmentInfo } = useContext(AddMemberContext)
  const [selected, setSelected] = useState(cities?.[0]?.value || undefined)

  useEffect(() => {
    setSelected(enrollmentInfo?.billingInfo.billingLocation?.value || undefined)
  }, [enrollmentInfo, setSelected])

  const onLocationChange = ({ index, option }: { index: number; option: { label: string; value: string } }) => {
    setSelected(option.value)
    setValue('billingLocation', option)
    trigger('billingLocation')
  }

  return (
    <>
      <div className={styles.textBoxMargin} data-testid="billingLocation">
        <Controller
          rules={{
            required: {
              value: true,
              message: t('PLEASE_MAKE_SELECTION'),
            },
          }}
          control={control}
          name="billingLocation"
          render={({ field, fieldState }) => {
            const { ...fields } = field
            return (
              <Dropdown
                {...fields}
                id={field.name}
                label={t('BILL_LOCATION')}
                selected={selected}
                error={fieldState?.invalid}
                helperText={fieldState.error ? fieldState.error?.message : t('BILL_LOC_TXT')}
                inputProps={undefined}
                inputRef={undefined}
                onChange={onLocationChange}
                options={cities?.map((city) => ({
                  label: city.label,
                  value: city.value,
                }))}
                placeholder=""
              />
            )
          }}
        />
      </div>
    </>
  )
}
