.halfRow {
  display: flex;
  justify-content: space-between;
}

.extraText {
  width: 80%;
  margin-top: 1.5rem;
}

.textBoxMargin {
  margin: 1.5rem 0px;
}

.justBottomMargin {
  margin-bottom: 5.5rem;
}

.radioRow {
  display: flex;
  margin-bottom: -1rem;
}

.subtitle {
  margin-top: 3.5rem;
}
