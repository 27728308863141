import { createContext, useState, type ReactNode, type Dispatch, type SetStateAction } from 'react'

import type { BenefitInfo, MemberGroupUI } from '../../types/domain'
import type { DependentRelationship } from '../../types/enums'

export type DropdownValue = {
  label: string
  value: string
}

export type DatePickerValue = {
  selected: Date
  input: string
}

export interface EmploymentInfo {
  hireDateCode: string
  hireDate: DatePickerValue | undefined
  eligibleEmpGrp: DropdownValue | undefined
  signatureDate: DatePickerValue | undefined
}

export interface DependentInfo {
  id: number
  firstName: string
  middleName: string
  lastName: string
  suffix: string
  dateOfBirth: DatePickerValue | undefined
  genderCode: string
  ssn: string
  relationship: DependentRelationship
}

interface EnrollmentData {
  personalInfo: {
    firstName: string
    middleName: string
    lastName: string
    suffix: string
    dateOfBirth: DatePickerValue | undefined
    genderCode: string
    ssn: string
  }
  mailingAddress: {
    country: DropdownValue | undefined
    address: string
    address2: string
    city: string
    state: DropdownValue | undefined
    zip: string
  }
  contactInfo: {
    email: string
    phoneNum: string
    phoneType: DropdownValue | undefined
  }
  employmentInfo: {
    hireDateCode: string
    hireDate: DatePickerValue | undefined
    eligibleEmpGrp: DropdownValue | undefined
    signatureDate: DatePickerValue | undefined
  }
  billingInfo: {
    billingLocation: DropdownValue | undefined
  }
  dependentInfo: DependentInfo[]
  benefitInfo: BenefitInfo[] | undefined
  memberGroups: MemberGroupUI[] | undefined
}

export interface AddMemberContextData {
  enrollmentInfo: EnrollmentData
  setEnrollmentInfo: Dispatch<SetStateAction<EnrollmentData>>
  isCanada: boolean
  setIsCanada: Dispatch<SetStateAction<boolean>>
  isHireDateSelected: boolean
  setIsHireDateSelected: Dispatch<SetStateAction<boolean>>
  isFullTime: boolean
  setIsFullTime: Dispatch<SetStateAction<boolean>>
}

export const AddMemberContext = createContext<AddMemberContextData>({
  enrollmentInfo: {
    personalInfo: {
      firstName: '',
      middleName: '',
      lastName: '',
      suffix: '',
      dateOfBirth: undefined,
      genderCode: '',
      ssn: '',
    },
    mailingAddress: {
      country: undefined,
      address: '',
      address2: '',
      city: '',
      state: undefined,
      zip: '',
    },
    contactInfo: {
      email: '',
      phoneNum: '',
      phoneType: undefined,
    },
    employmentInfo: {
      hireDateCode: '',
      hireDate: undefined,
      eligibleEmpGrp: undefined,
      signatureDate: undefined,
    },
    billingInfo: {
      billingLocation: undefined,
    },
    dependentInfo: [],
    benefitInfo: [],
    memberGroups: [],
  },
  setEnrollmentInfo: () => {},
  isCanada: false,
  setIsCanada: () => {},
  isHireDateSelected: false,
  setIsHireDateSelected: () => {},
  isFullTime: false,
  setIsFullTime: () => {},
})

type AddMemberProviderProps = {
  children: ReactNode
  defaultState?: Partial<EnrollmentData>
}

export const AddMemberProvider = ({ children, defaultState = {} }: AddMemberProviderProps) => {
  const [enrollmentInfo, setEnrollmentInfo] = useState<EnrollmentData>({
    personalInfo: {
      firstName: '',
      middleName: '',
      lastName: '',
      suffix: '',
      dateOfBirth: undefined,
      genderCode: '',
      ssn: '',
    },
    mailingAddress: {
      country: {
        label: 'United States',
        value: 'usa',
      },
      address: '',
      address2: '',
      city: '',
      state: undefined,
      zip: '',
    },
    contactInfo: {
      email: '',
      phoneNum: '',
      phoneType: {
        label: 'Mobile',
        value: 'mobile',
      },
    },
    employmentInfo: {
      hireDateCode: '',
      hireDate: undefined,
      eligibleEmpGrp: undefined,
      signatureDate: undefined,
    },
    billingInfo: {
      billingLocation: undefined,
    },
    dependentInfo: [],
    benefitInfo: [],
    memberGroups: [],
    ...defaultState,
  })

  const [isCanada, setIsCanada] = useState<boolean>(false)
  //const [isError, setIsError] = useState<boolean>(false)
  const [isHireDateSelected, setIsHireDateSelected] = useState<boolean>(false)
  const [isFullTime, setIsFullTime] = useState<boolean>(false)

  return (
    <AddMemberContext.Provider
      value={{
        enrollmentInfo,
        setEnrollmentInfo,
        isCanada,
        setIsCanada,
        isHireDateSelected,
        setIsHireDateSelected,
        isFullTime,
        setIsFullTime,
      }}
    >
      {children}
    </AddMemberContext.Provider>
  )
}
