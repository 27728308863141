import { Button } from '@component-library/helios'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { getConfigForCommonloginUrl } from '../../app/utils/getClientConfig'
import { PageContent } from '../components/PageContent/PageContent'
import { useAuth } from '../hooks/useAuth'

import * as styles from './Logout.module.css'

export const Logout = () => {
  const auth = useAuth()
  const navigate = useNavigate()
  const commonLoginUrl = getConfigForCommonloginUrl()

  useEffect(() => {
    if (auth.user) {
      navigate('/')
    }
  }, [auth.user, navigate])

  return (
    <PageContent>
      <main className={styles.logoutSection}>
        <div className={styles.centerText}>
          <h1>You have been successfully logged out.</h1>
        </div>
        <div className={styles.centerText}>
          <div className={styles.buttonContainer}>
            <Button href={`${commonLoginUrl}`} variant="primary">
              Go to sign in
            </Button>
          </div>
        </div>
      </main>
    </PageContent>
  )
}
