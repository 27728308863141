@import '@component-library/helios/dist/style.css';

@import './font.css';

:root {
  ---max-width: 1152px;
}

:global(*) {
  -webkit-font-smoothing: antialiased !important; /* TODO: Remove `!important` when component-library global style are removed. */
}
