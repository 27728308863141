import { DatePicker, HelperText } from '@component-library/helios'
import { DateTime } from 'luxon'
import { type FC, useRef } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { DATE_OF_BIRTH } from '../../../common/enums/regex'

import * as styles from './Employment.module.css'

interface HireDatePickerProps {
  isFullTime?: boolean
}

const {
  MM_DD_YYYY: { regex, pattern },
} = DATE_OF_BIRTH

export const HireDatePicker: FC<HireDatePickerProps> = ({ isFullTime }: HireDatePickerProps) => {
  const { control } = useFormContext()
  const { t } = useTranslation('translation', { keyPrefix: 'Employment' })
  const inputRef = useRef<HTMLInputElement>(null)

  const validateHireDate = (dateString: { input: string }): true | string => {
    const today = DateTime.now()
    const hireDate = DateTime.fromFormat(dateString.input, 'MM/dd/yyyy')

    if (dateString.input === '') {
      return t('PLEASE_MAKE_SELECTION')
    }

    if (hireDate.invalidReason) {
      return t('INVALID_DATE')
    }

    if (hireDate > today.plus({ months: 3 })) {
      return t('FUTURE_DATE_ERROR')
    }

    return true
  }

  return (
    <>
      <div className={styles.textBoxMargin} data-testid="hireDate">
        <Controller
          rules={{
            required: {
              value: true,
              message: t('PLEASE_MAKE_SELECTION'),
            },
            pattern: {
              value: DATE_OF_BIRTH.MM_DD_YYYY.regex,
              message: DATE_OF_BIRTH.MM_DD_YYYY.message,
            },
            validate: validateHireDate,
          }}
          control={control}
          name="hireDate"
          render={({ field, fieldState }) => {
            const { ...fields } = field
            return (
              <>
                <div>
                  <DatePicker
                    {...fields}
                    id={field.name}
                    label={isFullTime ? t('FULL_TIME_LABEL') : t('PART_TIME_LABEL')}
                    error={fieldState?.invalid}
                    className=""
                    placeholder={''}
                    defaultValue={undefined}
                    minDate=""
                    maxDate={DateTime.now().plus({ months: 3 }).toISODate()}
                    isModal={false}
                    inputProps={undefined}
                    inputRef={inputRef}
                    localeTexts={undefined}
                  />
                </div>
                {fieldState.invalid && (
                  <div>
                    <HelperText id={`hire-date-error`} error>
                      {fieldState.error?.message}
                    </HelperText>
                  </div>
                )}
              </>
            )
          }}
        />
      </div>
    </>
  )
}
