export const getConfigForCommonloginUrl = () => {
  return window.config.commonLoginUrl ?? null
}

export const getConfigForSLCHomeUrl = () => {
  return window.config.slcHomeUrl ?? null
}

export const getAuthServiceTokenName = () => {
  return window.config.authServiceTokenName ?? null
}

export const getAuthCookieDomain = () => {
  return window.config.authCookieDomain ?? null
}

export const getConfigForQualtricsUrl = () => {
  return window.config.qualtricsUrl ?? null
}

export const getConfigForQualtricsCookieName = () => {
  return window.config.qualtricsCookie ?? null
}

export const isLocal = window.config.env === 'local'

export const isQA = window.config.env === 'qa-dcs'

export const isStaging = window.config.env === 'stage-dcs'

export const isProduction = window.config.env === 'prod-dcs'
