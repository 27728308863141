import { Header, Link, Notification } from '@component-library/helios'
import type { ComponentProps, FC } from 'react'
import { useTranslation } from 'react-i18next'

import { getConfigForSLCHomeUrl } from '../../../app/utils/getClientConfig'
import { useAuth } from '../../hooks/useAuth'
import { useAuthToken } from '../../hooks/useAuthToken'
import { trackEvent } from '../../utils/analytics'
import type { Auth } from '../Auth/AuthContext'

import * as styles from './TopNav.module.css'

export const TopNav: FC = () => {
  const auth = useAuth()
  const [_, setAuthToken] = useAuthToken()
  const { t } = useTranslation('translation', { keyPrefix: 'TopNav' })
  const SLCHomeURL = getConfigForSLCHomeUrl()

  const utilityNavItems: ComponentProps<typeof Header>['utilityNavItems'] = []

  const handleExitClick = () => {
    trackEvent({
      ev_type: 'other',
      ev_action: 'clk',
      ev_title: 'notification bar:link:exit new experience',
    })
    window.sessionStorage.clear()
    setAuthToken({ token: '', createdAt: 0 })
  }

  if (auth.user) {
    const accountItems = getAccountMenuItems(auth)

    utilityNavItems.push({
      id: 'account',
      type: 'ACCOUNT',
      label: [auth.user?.firstName, auth.user?.lastName].join(' '),
      href: '#',
      children: accountItems,
    })
  }

  return (
    <div data-section="do-not-track">
      {auth.user && (
        <div className={styles.exitnotification}>
          <Notification closeLabel="Close" variant="info">
            <>
              {t('UTILITY_BAR_MSG')}
              <Link href={`${SLCHomeURL}`} onClick={handleExitClick}>
                {' '}
                {t('UTILITY_BAR_EXIT_LINK')}
              </Link>
            </>
          </Notification>
        </div>
      )}
      <Header
        skipLinks={[
          {
            href: '#',
            label: t('SKIP_TO_CONTENT'),
          },
          {
            href: '#',
            label: t('SKIP_TO_FOOTER'),
          },
        ]}
        logos={[
          {
            href: '/',
            imageAlt: 'Sun Life logo',
            imageSrc: '/public/images/Sun_Life_weblogo_127x31.svg',
            type: 'LEFT',
          },
          {
            href: '/',
            imageAlt: 'Sun Life logo',
            imageSrc: '/public/images/Sun_Life_weblogo_127x31.svg',
            type: 'MOBILE',
          },
        ]}
        mainNavItems={[]}
        utilityNavItems={utilityNavItems}
        menuToggleButtonLabel={t('OPEN_MENU')}
        menuCloseButtonLabel={t('OPEN_MENU')}
        showBrandColor
      />
    </div>
  )
}

function getAccountMenuItems(auth: Auth) {
  const accountMenutItems = []

  const handleLogOutClick = () => {
    trackEvent({
      ev_type: 'other',
      ev_action: 'clk',
      ev_title: 'username menu:child link=log out',
    })
    window.sessionStorage.clear()
    auth.logout()
  }

  accountMenutItems.push({
    label: 'Log out',
    onClick: () => handleLogOutClick(),
  })

  return accountMenutItems
}
