export const termsConditions = {
  firstStatement:
    'AS THE EMPLOYER, YOU ARE RESPONSIBLE TO CONFIRM THAT EMPLOYEES AND DEPENDENTS ARE ELIGIBLE UNDER THE SUN LIFE GROUP POLICY FOR THE COVERAGE IN WHICH YOU ENROLL THEM!',
  listTitle: 'You acknowledge you have read and agree with the following:',
  list: {
    one: 'For coverage that requires an Evidence of Insurability (“EOI”) application, you must not enroll employees or dependents for that coverage unless the EOI application was provided to and approved in writing by Sun Life;',
    two: 'Coverage requiring EOI approval is not in effect unless and until Sun Life notifies the employer in writing that it has approved such coverage;',
    three:
      'Even if EOI is not required, the eligibility of an employee or dependent for coverage under the Sun Life group policy may be limited by benefit amount, age or other factors;',
    four: 'The employer will deduct premiums from an employee’s paycheck, and remit premiums to Sun Life, only after verifying eligibility for coverage, including EOI approval when necessary; and',
    five: 'The employer must promptly unenroll and stop deducting or remitting premiums to Sun Life for any coverage if an employee or dependent is no longer eligible for such coverage.',
  },
  secondStatement:
    'THE EMPLOYER UNDERSTANDS AND AGREES THAT IT IS NOT AN AGENT FOR SUN LIFE AND THAT SUN LIFE SHALL NOT BE LIABLE FOR THE EMPLOYER’S FAILURE TO ADHERE TO THESE REQUIREMENTS.',
  ackStatement:
    'By selecting “Complete enrollment,” you acknowledge that you agree to these terms and have confirmed that all employees and dependents for whom premiums are paid are eligible under the Sun Life group policy for the respective coverages in which you enroll them.',
}
