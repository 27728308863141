import { Body, Card, Heading } from '@component-library/helios'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import type { DetailBenefitListItem } from 'types/domain'

import { BenefitCategory } from '../../../types/enums'

import * as styles from './MemberBenefits.module.css'
import type { MemberBenefitsColumns } from './MemberBenefitsTable/MemberBenefitsTable'
import { MemberBenefitsTable } from './MemberBenefitsTable/MemberBenefitsTable'

interface CoverageCardMapping {
  category: BenefitCategory
  columns: MemberBenefitsColumns[]
  title: string
}

const coverageCardMappings: CoverageCardMapping[] = [
  // When modifying columns, make sure they stay aligned across all benefit tables
  {
    title: 'LIFE_TITLE',
    category: BenefitCategory.LIFE,
    columns: ['benefit', 'effectiveDate', 'coverageAmt', 'requestedExcess', 'totalRequested'],
  },
  {
    title: 'DISABILITY_TITLE',
    category: BenefitCategory.DISABILITY,
    columns: ['benefit', 'effectiveDate', 'coverageAmt', 'plan', 'requestedExcess', 'totalRequested'],
  },
  {
    title: 'EAP_TITLE',
    category: BenefitCategory.EAP,
    columns: ['benefit', 'effectiveDate', 'status'],
  },
  {
    title: 'LEAVE_SOL_TITLE',
    category: BenefitCategory.LEAVE,
    columns: ['benefit', 'effectiveDate', 'status'],
  },
  {
    title: 'DENTAL_TITLE',
    category: BenefitCategory.DENTAL,
    columns: ['benefit', 'effectiveDate', 'status', 'plan'],
  },
  {
    title: 'HOSP_IND_TITLE',
    category: BenefitCategory.HOSPITAL_INDEMNITY,
    columns: ['benefit', 'effectiveDate', 'status', 'plan'],
  },
  {
    title: 'ACCIDENT_TITLE',
    category: BenefitCategory.ACCIDENT,
    columns: ['benefit', 'effectiveDate', 'status', 'plan'],
  },
  {
    title: 'CRIT_ILL_TITLE',
    category: BenefitCategory.CRITICAL_ILLNESS,
    columns: ['benefit', 'effectiveDate', 'coverageAmt', 'plan', 'requestedExcess', 'totalRequested'],
  },

  {
    title: 'CANCER_TITLE',
    category: BenefitCategory.CANCER,
    columns: ['benefit', 'effectiveDate', 'status', 'plan'],
  },
  {
    title: 'VISION_TITLE',
    category: BenefitCategory.VISION,
    columns: ['benefit', 'effectiveDate', 'status'],
  },
]

type MemberBenefitProps = {
  benefits: DetailBenefitListItem[]
}

export const MemberBenefits: FC<MemberBenefitProps> = ({ benefits }: MemberBenefitProps) => {
  const benefitsByCategory: Map<string, DetailBenefitListItem[]> = new Map<string, DetailBenefitListItem[]>()

  const { t } = useTranslation('translation', { keyPrefix: 'MemberDetail' })

  if (benefits.length === 0) {
    return (
      <div className={styles.noBenefits}>
        <Card heading="">
          <Body variant="medium">{t('NO_BENEFITS')}</Body>
        </Card>
      </div>
    )
  }

  benefits.forEach((benefit) => {
    if (benefitsByCategory.has(benefit.category)) {
      benefitsByCategory.get(benefit.category)?.push(benefit)
    } else {
      benefitsByCategory.set(benefit.category, [benefit])
    }
  })

  return coverageCardMappings.map((coverageCardMapping) => {
    const categoryBenefits = benefitsByCategory.get(coverageCardMapping.category) || []
    if (categoryBenefits.length == 0) {
      return null
    }

    return (
      <div key={coverageCardMapping.category} className={styles.cardMargin}>
        <Heading variant="5" as="h5">
          {t(coverageCardMapping.title)}
        </Heading>

        <MemberBenefitsTable benefits={categoryBenefits} columns={coverageCardMapping.columns} />
      </div>
    )
  })
}
