import { Table, Badge } from '@component-library/helios'
import type { ComponentProps, FC } from 'react'
import { useTranslation } from 'react-i18next'
import type { DetailBenefitListItem } from 'types/domain'

import { currencyFormatter, formatDateShortMonthWithYear } from '../../../utils/format'
import { ScrollableContainer } from '../../ScrollableContainer/ScrollableContainer'

import { BenefitStatusBadge } from './BenefitStatusBadge'
import * as styles from './MemberBenefitsTable.module.css'

export type MemberBenefitsColumns =
  | 'benefit'
  | 'effectiveDate'
  | 'coverageAmt'
  | 'requestedExcess'
  | 'totalRequested'
  | 'plan'
  | 'status'
type MemberBenefitsTableProps = {
  columns: MemberBenefitsColumns[]
  benefits: DetailBenefitListItem[]
}

const columnDefinitons: Record<MemberBenefitsColumns, ComponentProps<typeof Table>['columns'][0]> = {
  // When modifying columns, make sure they stay aligned across all benefit tables
  benefit: {
    field: 'benefit',
    title: 'BENEFIT',
    align: 'left',
    width: '270px',
  },
  effectiveDate: {
    field: 'effectiveDate',
    title: 'EFF_DATE',
    align: 'left',
    width: '200px',
  },
  coverageAmt: {
    field: 'coverageAmt',
    title: 'COVERAGE_AMT',
    align: 'left',
    width: '280px',
  },
  status: {
    field: 'status',
    title: 'STATUS',
    align: 'left',
    width: '280px',
  },
  plan: {
    field: 'plan',
    title: 'PLAN',
    align: 'left',
    width: '220px',
  },
  requestedExcess: {
    field: 'requestedExcess',
    title: 'REQUESTED_EXCESS',
    align: 'left',
    width: '220px',
  },
  totalRequested: {
    field: 'totalRequested',
    title: 'TOTAL_REQUESTED',
    align: 'left',
    width: '160px',
  },
}

const isPendingEOIBenefit = (benefit: DetailBenefitListItem) => benefit.pendingAmount !== 0

export const MemberBenefitsTable: FC<MemberBenefitsTableProps> = ({ benefits, columns }: MemberBenefitsTableProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'MemberDetail' })

  const hasPendingEOIBenefits = !!benefits.find(isPendingEOIBenefit)

  if (!benefits.length) {
    return null
  }

  return (
    <ScrollableContainer width="1000px">
      <Table
        className={styles.table}
        columns={columns
          .filter((column) => {
            if (['requestedExcess', 'totalRequested'].includes(column)) {
              return hasPendingEOIBenefits
            }
            return true
          })
          .map((column) => ({ ...columnDefinitons[column], title: t(columnDefinitons[column].title) }))}
        rows={benefits.map((benefit) => ({
          benefit: benefit.benefit,
          effectiveDate:
            formatDateShortMonthWithYear(benefit.effectiveDate) +
            (benefit.terminationDate ? ' - ' + formatDateShortMonthWithYear(benefit.terminationDate) : ''),
          plan: benefit.plan ? benefit.plan : '—',
          coverageAmt: (
            <div className={styles.badge}>
              <span>{currencyFormatter(benefit.approvedAmount)}</span>
              <BenefitStatusBadge status={benefit.status} />
            </div>
          ),
          status: (
            <div className={styles.badge}>
              <BenefitStatusBadge status={benefit.status} />
            </div>
          ),
          requestedExcess: isPendingEOIBenefit(benefit) ? (
            <div className={styles.badge}>
              <span>{currencyFormatter(benefit.pendingAmount)}</span>
              <Badge label={t('PENDING', { keyPrefix: 'BenefitStatus' })} variant={'warning'} />
            </div>
          ) : (
            '—'
          ),
          totalRequested: isPendingEOIBenefit(benefit)
            ? currencyFormatter(benefit.approvedAmount + benefit.pendingAmount)
            : '—',
          // Actions not in scope for Q2
          //   actions: (
          //     <MenuDropdown
          //       toggleClass={styles.dropdownIcon}
          //       data={{
          //         title: <Link as={ReactRouterDomLink} to={`/member`}></Link>,
          //       }}
          //       dropdownIcon={<i className="far fa-ellipsis-v" aria-hidden="true" />}
          //     />
          //   ),
        }))}
      />
    </ScrollableContainer>
  )
}
