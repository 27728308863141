import { AdvancedPagination, Body, Link, MenuDropdown, Table } from '@component-library/helios'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as ReactRouterDomLink } from 'react-router-dom'
import type { BenefitListItem, MemberListItem, SortingQueryParams } from 'types/domain'

import { trackEvent } from '../../utils/analytics'
import { formatDateShortMonthWithYear } from '../../utils/format'
import { ScrollableContainer } from '../ScrollableContainer/ScrollableContainer'

import { MemberListBenefitStatus } from './MemberListBenefitStatus'
import * as styles from './MemberListTable.module.css'

const memberSortFieldMap: Record<string, string> = {
  memberName: 'name_full',
  lastUpdated: 'compass_updated_at',
}

type InitialPagination = {
  page: number
  pageSize: number
}

const defaultInitialPagination: InitialPagination = {
  page: 1,
  pageSize: 25,
}

type MemberListTableProps = {
  members: MemberListItem[]
  page: string
  pageSize: string
  onSortChange: (sort: SortingQueryParams) => void
  onPageChange?: (page: number) => void
  onRowsPerPageChange?: (rowsPerPage: number) => void
  totalItems: number
}

export const MemberListTable = ({
  members,
  page,
  pageSize,
  onSortChange,
  onPageChange,
  onRowsPerPageChange,
  totalItems,
}: MemberListTableProps) => {
  const initialPage = useMemo(() => Number(page) ?? defaultInitialPagination.page, [page])
  const initialItemsPerPage = useMemo(() => Number(pageSize) ?? defaultInitialPagination.pageSize, [pageSize])
  const [actionsClicked, setActionsClicked] = useState(false)
  const { t } = useTranslation('translation')

  useEffect(() => {
    onPageChange?.(initialPage)
    onRowsPerPageChange?.(initialItemsPerPage)

    // NOTE: only on inital page load, hence no dependencies / ignore lint warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSortChange = (sortKey: string, direction: SortDirection) => {
    const sortingQueryParams: SortingQueryParams = { sortKey: memberSortFieldMap[sortKey], sortDirection: direction }
    onSortChange(sortingQueryParams)
    trackEvent({
      ev_type: 'other',
      ev_action: 'clk',
      ev_title: `table:sort:category=${sortKey}:sort order=${direction}`,
    })
  }

  const getBenefitCategories = (benefits: BenefitListItem[]) => {
    const uniqueBenefitCategories = [
      ...new Set(benefits.map((benefit) => t(benefit.category, { keyPrefix: 'BenefitCategories' }))),
    ].join(', ')
    return uniqueBenefitCategories
  }

  const handleActionsClick = () => {
    setActionsClicked(!actionsClicked)
    if (!actionsClicked) {
      trackEvent({
        ev_type: 'other',
        ev_action: 'clk',
        ev_title: 'three dot action menu:open',
      })
    }
  }

  const handleMemberNameClick = () => {
    trackEvent({
      ev_type: 'other',
      ev_action: 'clk',
      ev_title: 'link:member name',
    })
  }

  const handleChildLinkClick = () => {
    setActionsClicked(!actionsClicked)
    trackEvent({
      ev_type: 'other',
      ev_action: 'clk',
      ev_title: 'three dot action menu:child link=view member',
    })
  }

  const handlePageRowsClick = () => {
    trackEvent({
      ev_type: 'other',
      ev_action: 'clk',
      ev_title: 'pagination dropdown:rows per page',
    })
  }

  const handlePageChangeClick = () => {
    trackEvent({
      ev_type: 'other',
      ev_action: 'clk',
      ev_title: 'pagination arrows:arrow clicked',
    })
  }

  return (
    <div data-section="do-not-track">
      <ScrollableContainer width="1000px">
        <Table
          title=""
          columns={[
            {
              field: 'memberName',
              title: 'Name',
              sortable: true,
              align: 'left',
              width: '20%',
            },
            {
              field: 'benefits',
              title: 'Benefits',
              align: 'left',
              width: { sm: '35%' },
            },
            {
              field: 'benefitStatus',
              title: 'Benefit status',
              align: 'left',
              width: { sm: '25%' },
            },
            {
              field: 'lastUpdated',
              title: 'Last modified',
              sortable: true,
              align: 'left',
              width: '15%',
            },
            {
              field: 'actions',
              title: 'Actions',
              align: 'right',
              width: '5%',
            },
          ]}
          rows={members?.map((member) => ({
            memberName: (
              <>
                <div data-section="do-not-track" className={styles.memberName} onClick={handleMemberNameClick}>
                  <Link as={ReactRouterDomLink} to={`/member/${member.id}`} state={member} size="sm">
                    <span data-section="do-not-track">
                      {member.nameLast}, {member.nameFirst}
                    </span>
                  </Link>
                  <br />
                </div>
                <div data-section="do-not-track">
                  <Body variant="small" color="caption">
                    Member #{member.externalId}
                  </Body>
                </div>
              </>
            ),
            benefits: getBenefitCategories(member.benefits),
            benefitStatus: <MemberListBenefitStatus benefits={member.benefits} />,
            lastUpdated: member.updatedAt ? formatDateShortMonthWithYear(member.updatedAt) : '',
            actions: (
              <MenuDropdown
                onClick={handleActionsClick}
                toggleClass={styles.dropdownIcon}
                data={{
                  title: (
                    <Link
                      as={ReactRouterDomLink}
                      to={`/member/${member.id}`}
                      state={member}
                      size="sm"
                      onClick={handleChildLinkClick}
                    >
                      {t('MemberList.VIEW_MEMBER')}
                    </Link>
                  ),
                }}
                dropdownIcon={<i className="far fa-ellipsis-v" aria-hidden="true" />}
              />
            ),
          }))}
          onSort={handleSortChange}
          className={styles.memberListTable}
        />
      </ScrollableContainer>
      <AdvancedPagination
        showItemsPerPage
        itemsPerPageOptions={[25, 50, 75, 100]}
        page={initialPage}
        itemsPerPage={initialItemsPerPage}
        totalItems={totalItems}
        onChange={(page: number) => {
          onPageChange?.(page)
          handlePageChangeClick()
        }}
        onItemsPerRowChange={(rowsPerPage: number) => {
          onRowsPerPageChange?.(rowsPerPage)
          handlePageRowsClick()
        }}
      />
    </div>
  )
}
