import type {
  Auth_ExtendRequest,
  Auth_LogoutRequest,
  Member_AddRequest,
  Member_GetByIDRequestPathParams,
  Member_GetDentalIDCardRequestPathParams,
  Member_ListRequestQueryParams,
  Transaction_GetByIDRequestPathParams,
} from 'types/domain'
import type { FeatureToggleName } from 'types/enums'

export const queryKeys = {
  /*
   * Auth
   */
  login() {
    return ['auth', 'login'] as const
  },
  extend(request?: Auth_ExtendRequest) {
    return ['auth', 'extend', request] as const
  },
  logout(request?: Auth_LogoutRequest) {
    return ['auth', 'logout', request] as const
  },
  /*
   * Employer member groups
   */
  getEmployerMemberGroups() {
    return ['employerMemberGroups'] as const
  },
  /*
   * Employer Enrollment Info
   */
  getEmployerEnrollmentInfo() {
    return ['employerEnrollmentInfo'] as const
  },
  /*
   * Feature toggle
   */
  getFeatureToggleByName(name: FeatureToggleName) {
    return ['featuretoggle', name] as const
  },
  /*
   * Member
   */
  addMember(request?: Member_AddRequest) {
    return ['member', request] as const
  },
  getMembers(request?: Member_ListRequestQueryParams) {
    return ['member', request] as const
  },
  getMemberById(request?: Partial<Member_GetByIDRequestPathParams>) {
    return ['member', request] as const
  },
  getDentalIDCard(request?: Partial<Member_GetDentalIDCardRequestPathParams>) {
    return ['member', request] as const
  },
  /*
   * Transaction
   */
  getTransactionById(request?: Partial<Transaction_GetByIDRequestPathParams>) {
    return ['transaction', request] as const
  },
} as const
