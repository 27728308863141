import { Dropdown } from '@component-library/helios'
import { type FC, useEffect, useState, useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AddMemberContext } from '../../../../context/AddMemberContext'
import * as styles from '../PersonalInfo/PersonalInfo.module.css'

type CountryProps = {
  getCountry: (label: string, value: string) => void
}

const countries = [
  {
    label: 'United States',
    value: 'usa',
  },
  {
    label: 'Canada',
    value: 'canada',
  },
]

export const Country: FC<CountryProps> = ({ getCountry }: CountryProps) => {
  const { control, setValue } = useFormContext()
  const { t } = useTranslation('translation', { keyPrefix: 'PersonalInfo' })
  const { enrollmentInfo } = useContext(AddMemberContext)
  const [selected, setSelected] = useState(countries?.[0]?.value)

  useEffect(() => {
    setSelected(enrollmentInfo?.mailingAddress?.country?.value || countries[0].value)
  }, [enrollmentInfo, setSelected])

  const onCountryChange = ({ index, option }: { index: number; option: { label: string; value: string } }) => {
    getCountry(option.label, option.value)
    setSelected(option.value)
    setValue('country', option)
  }

  return (
    <>
      <div className={styles.textBoxMargin} data-testid="country">
        <Controller
          render={({ field, fieldState }) => {
            const { ...fields } = field
            return (
              <Dropdown
                {...fields}
                id={field.name}
                label={t('COUNTRY')}
                selected={selected}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                inputProps={undefined}
                inputRef={undefined}
                onChange={onCountryChange}
                options={countries?.map((country) => ({
                  label: country.label,
                  value: country.value,
                }))}
                placeholder=""
              />
            )
          }}
          rules={{
            required: {
              value: true,
              message: t('REQUIRED'),
            },
          }}
          control={control}
          name="country"
        />
      </div>
    </>
  )
}
