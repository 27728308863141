import { Radio, HelperText } from '@component-library/helios'
import { type FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface BenefitPlansProps {
  label?: string
  isViewOnly?: boolean
  helperText?: string
  isRequired?: boolean
  plans?: string[]
  electedPlan?: string
}

export const BenefitPlans: FC<BenefitPlansProps> = ({ isViewOnly = false, isRequired = true }: BenefitPlansProps) => {
  const { control } = useFormContext()
  const { t } = useTranslation('translation', { keyPrefix: 'Benefits' })

  return (
    <>
      <div data-cy="radio_plan_group">
        <Controller
          control={control}
          name="benefitPlan"
          rules={{
            required: {
              value: isRequired,
              message: t('PLEASE_MAKE_SELECTION'),
            },
          }}
          render={({ field, fieldState }) => {
            const { ...fields } = field
            return (
              <>
                <div>
                  <Radio
                    {...fields}
                    id="plan-radio-1"
                    label="Choice 1"
                    value="CHOICE1"
                    name="benefitPlan"
                    isInvalid={fieldState.invalid}
                    checked={field.value === 'CHOICE1'}
                    data-cy="plan-radio-1"
                    key={1}
                    disabled={isViewOnly}
                  />
                  <Radio
                    {...fields}
                    id="plan-radio-2"
                    label="Choice 2"
                    value="CHOICE2"
                    name="benefitPlan"
                    isInvalid={fieldState.invalid}
                    checked={field.value === 'CHOICE2'}
                    data-cy="plan-radio-2"
                    key={2}
                    disabled={isViewOnly}
                  />
                </div>
                {fieldState.invalid && (
                  <div>
                    <HelperText id={`plan-error`} error>
                      {fieldState.error?.message}
                    </HelperText>
                  </div>
                )}
              </>
            )
          }}
        />
      </div>
    </>
  )
}
