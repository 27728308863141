import { Body, MenuDropdown, Table } from '@component-library/helios'
import { useState, type FC, type ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'

import { type DependentInfo } from '../../../../app/context/AddMemberContext'

import { formatDateShortMonthWithYear, formatFullName, formatSSN, getAge } from './../../../../app/utils/format'
import * as styles from './Dependents.module.css'

export type DependentsTableProps = {
  dependents: DependentInfo[]
  actions?: (dependent: DependentInfo) => ComponentProps<typeof MenuDropdown>['data']['children']
}

export const DependentsTable: FC<DependentsTableProps> = ({ actions, dependents }: DependentsTableProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'Dependents' })
  const [actionsClicked, setActionsClicked] = useState(false)

  return (
    <Table
      className={styles.dependentsTable}
      columns={[
        {
          field: 'depName',
          title: 'Name',
          align: 'left',
          width: '30%',
        },
        {
          field: 'dateOfBirth',
          title: 'Date of Birth',
          align: 'left',
          width: '20%',
        },
        {
          field: 'ssn',
          title: 'SSN',
          align: 'left',
          width: '20%',
        },
        {
          field: 'gender',
          title: 'Gender',
          align: 'left',
          width: '20%',
        },
        ...(actions
          ? [
              {
                field: 'actions',
                title: 'Actions',
                align: 'right' as const,
                width: '10%',
              },
            ]
          : []),
      ]}
      rows={dependents.map((dependent) => ({
        depName: formatFullName(dependent),
        dateOfBirth: dependent.dateOfBirth?.selected ? (
          <>
            <Body variant="small">{formatDateShortMonthWithYear(dependent.dateOfBirth.selected)}</Body>
            <br />
            <Body variant="small" color="caption">
              {t('YEARS_OLD', { count: getAge(dependent.dateOfBirth.selected) })}
            </Body>
          </>
        ) : (
          '-'
        ),
        ssn: formatSSN(dependent.ssn),
        gender: t('MEMBERGENDER.' + dependent.genderCode),
        actions: actions ? (
          <MenuDropdown
            onClick={() => setActionsClicked(!actionsClicked)}
            toggleClass={styles.dropdownIcon}
            data={{
              children: actions(dependent),
            }}
            dropdownIcon={<i className="far fa-ellipsis-v" aria-hidden="true" />}
          />
        ) : null,
      }))}
    />
  )
}
