import { useTranslation } from 'react-i18next'
import type { BenefitListItem } from 'types/domain'

import * as styles from './MemberListBenefitStatus.module.css'

interface StatusOccurrence {
  status: string
  count: number
}

export function MemberListBenefitStatus({ benefits }: { benefits: BenefitListItem[] }) {
  const { t } = useTranslation('translation', { keyPrefix: 'BenefitStatus' })

  const benefitCount = benefits.length
  const pendingGroupLabel = 'PENDING'

  //use reduce to work out the number of occurrences of each status
  const benefitStatusOccurrences = benefits.reduce(
    (r, benefit) => {
      const { arr, map } = r
      const item = map.get(benefit.status)
      item ? item.count++ : map.set(benefit.status, (arr[arr.length] = { status: benefit.status, count: 1 }))

      if (benefit.hasPendingEoi) {
        const item = map.get(pendingGroupLabel)
        item ? item.count++ : map.set(pendingGroupLabel, (arr[arr.length] = { status: pendingGroupLabel, count: 1 }))
      }

      return r
    },
    { arr: [], map: new Map() } as { arr: StatusOccurrence[]; map: Map<string, StatusOccurrence> },
  ).arr
  //display a list of statuses, if all benefits in status replace the count with 'All'
  return (
    <div className="text-nowrap">
      {benefitStatusOccurrences.map((benefitStatus) => {
        const dotColor = 'color-' + benefitStatus.status
        const countDisplay = benefitStatus.count == benefitCount ? t('ALL') : benefitStatus.count
        return (
          <li style={{ listStyleType: 'none' }} key={benefitStatus.status}>
            <span className={`${styles.dot} ${styles[dotColor]}`}></span> {countDisplay + ' ' + t(benefitStatus.status)}
          </li>
        )
      })}
    </div>
  )
}
