import { Outlet } from 'react-router-dom'

import { LegalFooter } from '../LegalFooter/LegalFooter'
import { TopNav } from '../TopNav/TopNav'

export const MainLayout = () => {
  return (
    <div>
      <TopNav />
      <main>{<Outlet />}</main>
      <footer>
        <LegalFooter />
      </footer>
    </div>
  )
}
