.dependentContainer {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.dependentContainer [class*='sl-btn'] {
  margin-top: 1rem;
  min-width: 330px;
}

.dependentContainer [class='table-responsive'] {
  overflow: inherit;
}

.dependentContainer [class='table'] {
  margin-top: 0.75rem;
}

.dropdownIcon {
  padding: 4px !important;
  width: 1.5rem;
  justify-content: right;
  margin-left: auto;
}

.dropdownIcon .dropdownLabel {
  display: none;
}

.dependentsTable {
  td {
    vertical-align: top;
  }
}
