import { useEffect } from 'react'
import { NavigationType, useLocation, useNavigationType } from 'react-router-dom'

import { useAuth } from '../../hooks/useAuth'
import { usePrevious } from '../../hooks/usePrevious'
import { useViewMemberManagementTracker } from '../../hooks/useViewMemberManagementTracker'
import { trackView } from '../../utils/analytics'
import { getAnalyticsBreadcrumbs } from '../../utils/formatAnalyticsBreadcrumbs'
import { getPageMeta } from '../../utils/pageMeta'

export const ViewTracker = () => {
  const auth = useAuth()
  const navigationType = useNavigationType()
  const location = useLocation()
  const prevLocation = usePrevious(location)
  const trackViewMemberManagement = useViewMemberManagementTracker()

  useEffect(() => {
    // We usually don't want to track redirect that replace an URL, it causes
    // the side effect to see duplicated tracking events. However, in the case
    // of the default homepage (/) that gets redirected to `/all-clients` we
    // want to track that initial page load which usually happen when after a
    // user login.
    if (navigationType === NavigationType.Replace && location.pathname !== '/members') return

    const hasPathnameChanged = location.pathname !== prevLocation?.pathname

    if (hasPathnameChanged) {
      const meta = getPageMeta(location.pathname)
      const data = {
        page_canonical_url: window.location.origin + location.pathname,
        page_breadcrumb: getAnalyticsBreadcrumbs(location.pathname),
        page_language: 'en',
        page_category: meta?.category ?? 'Uncategorized',
        page_subcategory: meta?.subCategory ?? 'Uncategorized',
      }

      trackView(data)
    }
  }, [
    auth.user?.roles,
    auth.user?.participantId,
    location,
    prevLocation?.pathname,
    navigationType,
    trackViewMemberManagement,
  ])

  return null
}
