.policyinfo label {
  color: var(--grey-700);
  padding-right: 0.5rem;
}

.greybox {
  padding: 1.5rem 1.5rem;
  background-color: #ffffff;
  border: 2px solid #dcdedf;
}

.spacer {
  margin-top: 1rem;
}
