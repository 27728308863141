import { Badge, Body, Button, Divider, Heading } from '@component-library/helios'
import { useState, type FC, useContext, useEffect } from 'react'
import type { UseFormReturn } from 'react-hook-form'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import type { BenefitInfo } from 'types/domain'

import { AddMemberContext } from '../../../context/AddMemberContext'
import { AddMemberProgressContext } from '../../../context/AddMemberProgressContext'

import { BenefitPlans } from './BenefitPlans'
import * as styles from './Benefits.module.css'
import { TieredCoverage } from './TieredCoverage'
import { VolumeBasedCoverage } from './VolumeBasedCoverage'

type BenefitInfoUIActionProps = {
  elected: boolean
  waived: boolean
}

type BenefitListCardProps = {
  benefit: BenefitInfo & BenefitInfoUIActionProps
  isViewingOptions: boolean
  setIsViewingOptions: (isViewingOptions: boolean) => void
}

enum CardStatus {
  CLOSED,
  VIEW,
}

export const BenefitListCard: FC<BenefitListCardProps> = ({
  benefit,
  isViewingOptions,
  setIsViewingOptions,
}: BenefitListCardProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'Benefits' })
  const [cardStatus, setCardStatus] = useState(CardStatus.CLOSED)
  const { enrollmentInfo, setEnrollmentInfo } = useContext(AddMemberContext)
  const methods: UseFormReturn<BenefitInfo> = useForm<BenefitInfo>({
    mode: 'onChange',
  })
  const { trigger, getValues } = methods
  const { setIsError } = useContext(AddMemberProgressContext)
  const [disabled, setDisabled] = useState(false)
  // const memberName = enrollmentInfo?.personalInfo.firstName
  // const spouseName = enrollmentInfo?.dependentInfo.filter((dependent) => dependent.relationship === 'SPOUSE')[0]
  //   ?.firstName
  // const childrenNames = enrollmentInfo?.dependentInfo
  //   .filter((dependent) => dependent.relationship === 'DEPENDENT')
  //   .map((dependent) => dependent.firstName)
  //   .join(', ')
  // const coveredMembers = [memberName, spouseName, childrenNames].filter((mbr) => mbr != '').join(', ')

  const onClickSave = async () => {
    const isValid = await trigger()
    if (!isValid) {
      setIsError(true)
    }
    if (isValid) {
      setIsError(false)
      const { electedTier } = getValues()
      benefit.electedTier = electedTier
      const benefitInfo: BenefitInfo[] = enrollmentInfo!.benefitInfo!.filter(
        (benefitInfo) => benefitInfo.id !== benefit.id,
      )

      setEnrollmentInfo((enrollmentInfo) => ({
        ...enrollmentInfo,
        benefitInfo: [...benefitInfo, benefit],
      }))
      benefit.elected = true
      setCardStatus(CardStatus.CLOSED)
      setIsViewingOptions(false)
    }
    return isValid
  }

  const waiveBenefit = () => {
    benefit.elected = false
    benefit.waived = true
    const benefitInfo: BenefitInfo[] = enrollmentInfo!.benefitInfo!.filter(
      (benefitInfo) => benefitInfo.id !== benefit.id,
    )
    setEnrollmentInfo((enrollmentInfo) => ({
      ...enrollmentInfo,
      benefitInfo: [...benefitInfo, benefit],
    }))
  }

  const editBenefit = () => {
    benefit.waived = false
    benefit.elected ? (setIsViewingOptions(true), setCardStatus(CardStatus.VIEW)) : setCardStatus(CardStatus.CLOSED)
    const benefitInfo: BenefitInfo[] = enrollmentInfo!.benefitInfo!.filter(
      (benefitInfo) => benefitInfo.id !== benefit.id,
    )
    setEnrollmentInfo((enrollmentInfo) => ({
      ...enrollmentInfo,
      benefitInfo: [...benefitInfo, benefit],
    }))
  }

  useEffect(() => {
    isViewingOptions && cardStatus == CardStatus.CLOSED ? setDisabled(true) : setDisabled(false)
  }, [isViewingOptions, cardStatus])

  return (
    <>
      <div className={styles.benefitCard} style={disabled ? { backgroundColor: '#F9FAFA' } : {}}>
        <div className={styles.benefitCardHeader}>
          <Heading variant="4" className="pr-3">
            {benefit.benefitCategory}
          </Heading>
          {benefit.waived && <Badge variant="info" label="Waived" />}
          {benefit.elected && <Badge variant="success" label="Elected" />}

          {cardStatus !== CardStatus.VIEW && (
            <div className={styles.benefitCardActions}>
              {!benefit.waived && (
                <>
                  <Button variant="secondary" onClick={() => waiveBenefit()} disabled={disabled}>
                    Waive
                  </Button>
                  {!benefit.elected && (
                    <Button
                      variant="primary"
                      onClick={() => {
                        setIsViewingOptions(true)
                        setCardStatus(CardStatus.VIEW)
                      }}
                      disabled={disabled}
                    >
                      View options
                    </Button>
                  )}
                </>
              )}
              {(benefit.waived || benefit.elected) && (
                <Button variant="secondary" onClick={() => editBenefit()} disabled={disabled}>
                  Edit
                </Button>
              )}
            </div>
          )}
        </div>
        {cardStatus == CardStatus.CLOSED && benefit.elected && (
          <>
            <div>{benefit.electedTier && <Body variant="medium">{t(benefit.electedTier)}</Body>}</div>
            {/* <div>
              <Body variant="small">Covers {coveredMembers}</Body>
            </div> */}
          </>
        )}
        {cardStatus == CardStatus.VIEW && (
          <>
            <div className="mt-3 mb-3">
              <Body variant="medium-bold">Eligibility requirements</Body>
              <ul>
                <li className="mt-2">Minimum 30 hours worked per week</li>
                <li className="mt-2">Maximum dependent age is 26, but it increases to 35 for a full-time student.</li>
              </ul>
            </div>
            <div className="mt-4 mb-3">
              <FormProvider {...methods}>
                {benefit.plans && (
                  <>
                    <Body variant="medium-bold">Select plan</Body>
                    <BenefitPlans plans={benefit.plans} />
                  </>
                )}
                <Body variant="medium-bold">Select coverage</Body>

                {benefit.volumeBased ? (
                  <VolumeBasedCoverage tiers={benefit.tiers} />
                ) : (
                  <TieredCoverage tiers={benefit.tiers} electedTier={benefit.electedTier} />
                )}
              </FormProvider>
            </div>
            <div className={styles.benefitCardActions}>
              <Button
                variant="secondary"
                onClick={() => {
                  setIsViewingOptions(false)
                  setCardStatus(CardStatus.CLOSED)
                  // reset()
                }}
              >
                Cancel
              </Button>
              <Button variant="primary" onClick={() => onClickSave()}>
                Save
              </Button>
            </div>
            <div className="mt-3">
              <Divider orientation="horizontal" size="sm" />
              <p>Anticipated effective date Jun. 1, 2024.</p>
              {false && <p>Dental ID card available immediately after enrollment.</p>}
            </div>
          </>
        )}
      </div>
    </>
  )
}
