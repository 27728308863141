import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '../../utils/queryKeys'
import { endpoints } from '../endpoints'

export const useGetDentalIDCard = endpoints.Member_GetDentalIDCard.createRequestHook(
  (request, { params }, { enabled }: { enabled: boolean }) => {
    return useQuery({
      queryKey: queryKeys.getDentalIDCard({ ...params }),
      queryFn: () => request({ params }),
      enabled,
    })
  },
)
