import classNames from 'classnames/bind'
import { type PropsWithChildren } from 'react'

import * as styles from './Stack.module.css'

const cx = classNames.bind(styles)

type StackProps = PropsWithChildren<{
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  growFirst?: true
  gap?: number
}>

export const Stack = ({ children, growFirst, direction = 'column', gap = 0 }: StackProps) => (
  <div
    className={cx('stack', {
      growFirst,
      directionRow: direction === 'row',
    })}
    style={{
      flexDirection: direction,
      gap: `${gap}rem`,
    }}
  >
    {children}
  </div>
)
