import { createContext } from 'react'
import type { User } from 'types/domain'

export type Auth = {
  user?: User
  authToken?: AuthToken
  logout: () => void
}

export const AuthContext = createContext<Auth | undefined>(undefined)
