import { TextField, Dropdown } from '@component-library/helios'
import { type FC, useEffect, useState, useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { PHONE_REGEX } from '../../../../common/enums/regex'
import { AddMemberContext } from '../../../../context/AddMemberContext'
import * as styles from '../PersonalInfo/PersonalInfo.module.css'

const phoneTypes = [
  {
    label: 'Mobile',
    value: 'mobile',
  },
  {
    label: 'Home',
    value: 'home',
  },
  {
    label: 'Work',
    value: 'work',
  },
]

export const Phone: FC = () => {
  const { control, setValue } = useFormContext()
  const { t } = useTranslation('translation', { keyPrefix: 'PersonalInfo' })
  const { enrollmentInfo } = useContext(AddMemberContext)
  const [selected, setSelected] = useState(phoneTypes[0].value)

  useEffect(() => {
    setSelected(enrollmentInfo?.contactInfo?.phoneType?.value || phoneTypes[0].value)
  }, [enrollmentInfo, setSelected])

  const onPhoneTypeChange = ({ index, option }: { index: number; option: { label: string; value: string } }) => {
    setSelected(option.value)
    setValue('phoneType', option)
  }

  return (
    <>
      <div className={styles.phoneCtr}>
        <div className={styles.textBoxMargin + ' ' + styles.threeQuarRow} data-testid="phoneNum">
          <Controller
            render={({ field, fieldState }) => {
              const { onChange, ...fields } = field
              return (
                <TextField
                  {...fields}
                  id={field.name}
                  icon="fal fa-phone-alt"
                  iconPosition="before"
                  label={t('PHONE_NUM')}
                  error={fieldState.invalid}
                  helperText={fieldState.error ? fieldState.error?.message : t('OPTIONAL')}
                  maxLength={30}
                  onChange={(e) => {
                    onChange(e)
                  }}
                />
              )
            }}
            rules={{
              required: {
                value: false,
                message: '',
              },
              pattern: {
                value: PHONE_REGEX,
                message: t('INVALID'),
              },
            }}
            control={control}
            name="phoneNum"
          />
        </div>
        <div className={styles.textBoxMargin + ' ' + styles.oneQuarRow} data-testid="phoneType">
          <Controller
            render={({ field, fieldState }) => {
              const { ...fields } = field
              return (
                <Dropdown
                  {...fields}
                  id={field.name}
                  label={t('PHONE_TYPE')}
                  selected={selected}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  inputProps={undefined}
                  inputRef={undefined}
                  onChange={onPhoneTypeChange}
                  options={phoneTypes?.map((phoneType) => ({
                    label: phoneType.label,
                    value: phoneType.value,
                  }))}
                  placeholder=""
                />
              )
            }}
            rules={{
              required: {
                value: false,
                message: '',
              },
            }}
            control={control}
            name="phoneType"
          />
        </div>
      </div>
    </>
  )
}
