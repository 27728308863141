import { Radio, HelperText } from '@component-library/helios'
import { type FC, useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AddMemberContext } from '../../../context/AddMemberContext'

import * as styles from './Employment.module.css'
import { HireDatePicker } from './HireDatePicker'

interface HireDateSelectProps {
  label?: string
  isViewOnly?: boolean
  helperText?: string
  isRequired?: boolean
  inline?: boolean
}

export const HireDateSelect: FC<HireDateSelectProps> = ({
  isViewOnly = false,
  isRequired = true,
  inline = true,
}: HireDateSelectProps) => {
  const { control } = useFormContext()
  const { t } = useTranslation('translation', { keyPrefix: 'Employment' })
  const { isHireDateSelected, setIsHireDateSelected } = useContext(AddMemberContext)
  const { isFullTime, setIsFullTime } = useContext(AddMemberContext)

  const selectFullTimeHireDate = () => {
    setIsHireDateSelected(true)
    setIsFullTime(true)
  }

  const selectPartTimeHireDate = () => {
    setIsHireDateSelected(true)
    setIsFullTime(false)
  }

  return (
    <>
      <div data-cy="radio_hire_date_group" data-testid="hireDateSelect">
        <Controller
          control={control}
          name="hireDateCode"
          rules={{
            required: {
              value: isRequired,
              message: t('PLEASE_MAKE_SELECTION'),
            },
          }}
          render={({ field, fieldState }) => {
            const { ...fields } = field
            return (
              <>
                <div className={styles.radioRow}>
                  <div onClick={selectFullTimeHireDate}>
                    <Radio
                      {...fields}
                      id="gender-radio-full-time"
                      label={t('FULL_TIME')}
                      value="Full time"
                      name="hireDateCode"
                      isInvalid={fieldState.invalid}
                      checked={field.value === 'Full time'}
                      data-cy="radio_full_time"
                      key={1}
                      disabled={isViewOnly}
                      inline={inline}
                    />
                  </div>
                  <div onClick={selectPartTimeHireDate}>
                    <Radio
                      {...fields}
                      id="gender-radio-part-time"
                      label={t('PART_TIME')}
                      value="Part time"
                      name="hireDateCode"
                      isInvalid={fieldState.invalid}
                      checked={field.value === 'Part time'}
                      data-cy="radio_part_time"
                      key={2}
                      disabled={isViewOnly}
                      inline={inline}
                    />
                  </div>
                </div>
                {fieldState.invalid && (
                  <div>
                    <HelperText id={`hire-date-error`} error>
                      {fieldState.error?.message}
                    </HelperText>
                  </div>
                )}
                {isHireDateSelected ? (
                  <div>
                    <HireDatePicker isFullTime={isFullTime} />
                  </div>
                ) : (
                  ''
                )}
              </>
            )
          }}
        />
      </div>
    </>
  )
}
