import { Search } from '@component-library/helios'
import type { FC, ChangeEvent } from 'react'

import * as styles from './MemberSearch.module.css'

type MemberSearchProps = {
  label: string
  searchQuery: string
  onSearchQueryChange: (query: string) => void
}

export const MemberSearch: FC<MemberSearchProps> = ({ label, searchQuery, onSearchQueryChange }) => {
  return (
    <section className={styles.memberSearch}>
      <Search
        id="search-member-list"
        placeholder={label}
        variant="body"
        onClear={() => {
          onSearchQueryChange('')
        }}
        heading={undefined}
        suggestion={undefined}
        inputProps={{ 'aria-label': 'Search', value: searchQuery }}
        searchIconLabel="Search"
        clearIconLabel="Clear"
        onSearch={({ label }) => {
          onSearchQueryChange(label)
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          onSearchQueryChange(e.target.value)
        }}
      />
    </section>
  )
}
