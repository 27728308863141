import { Footer } from '@component-library/helios'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import * as styles from './LegalFooter.module.css'

export const LegalFooter: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'Footer' })
  const currentYear = new Date().getFullYear()

  return (
    <Footer
      content={{
        heading: t('HEADING'),
        body: (
          <div className={styles.legalFooterBody}>
            <p>{t('DISCLAIMER')}</p>
            <p>
              {t('LINKS', { currentYear })}
              <a className={styles.footerLink} href="https://www.sunlife.com/us" target="_blank" rel="noreferrer">
                www.sunlife.com/us
              </a>
            </p>
          </div>
        ),
      }}
      utilityMenu={{
        items: [
          {
            href: t('LEGAL_LINK'),
            title: t('LEGAL_TITLE'),
            target: '_blank',
          },
          {
            href: t('PRIVACY_LINK'),
            title: t('PRIVACY_TITLE'),
            target: '_blank',
          },
          {
            href: t('SECURITY_LINK'),
            title: t('SECURITY_TITLE'),
            target: '_blank',
          },
        ],
      }}
    />
  )
}
