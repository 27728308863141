export const NAME_FIELDS_REGEX = new RegExp("^[A-Za-z0-9-,.'ÁÉÍÓÚÜÑáéíóúüñ ]*$")
export const SSN_REGEX = new RegExp('^[0-9]{9,}$')
export const USA_ZIP_REGEX = new RegExp('^\\d{5}(-\\d{4})?$')
export const CANADA_POST_REGEX = new RegExp('^[A-Z0-9- ]*$')
export const PHONE_REGEX = new RegExp('^[0-9-() ]*$')
// Taken from HTML spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
// eslint-disable-next-line no-control-regex
export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/ /* eslint-disable-line */

export const DATE_OF_BIRTH = {
  MM_DD_YYYY: {
    regex: new RegExp(/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/),
    message: 'Please follow pattern: MM/DD/YYYY',
    pattern: 'MM/DD/YYYY',
  },
}
