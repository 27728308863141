{
  "translation": {
    "Footer": {
      "HEADING": "Life's brighter under the sun",
      "DISCLAIMER": "Except as noted, insurance products and prepaid dental products are underwritten or provided by Sun Life Assurance Company of Canada (Wellesley Hills, MA) (\"SLOC\"). In certain states prepaid dental products are provided by prepaid dental companies affiliated with SLOC. In New York, insurance products and prepaid dental products are underwritten or provided by Sun Life and Health Insurance Company (U.S.) (Lansing, MI) (\"SLHICUS\").",
      "LINKS": "© {{currentYear}} Sun Life Assurance Company of Canada, Wellesley Hills, MA 02481. All rights reserved. The Sun Life name and logo are registered trademarks of Sun Life Assurance Company of Canada. Visit us at ",
      "LEGAL_TITLE": "Legal",
      "LEGAL_LINK": "https://www.sunlife.com/sl/pslf-united-states/en/legal/",
      "PRIVACY_TITLE": "Privacy",
      "PRIVACY_LINK": "https://www.sunlife.com/sl/pslf-united-states/en/privacy/",
      "SECURITY_TITLE": "Security",
      "SECURITY_LINK": "https://www.sunlife.com/sl/pslf-united-states/en/security/"
    },
    "TopNav": {
      "SKIP_TO_CONTENT": "skip to content",
      "SKIP_TO_FOOTER": "skip to footer",
      "SIGN_OUT": "Sign Out",
      "UTILITY_BAR_MSG": "Don't forget to share feedback on the new experience.",
      "UTILITY_BAR_EXIT_LINK": "Return to Sun Life Connect home page."
    },
    "MemberList": {
      "PAGE_TITLE": "Members",
      "SEARCH_PLACEHOLDER": "Search by name or member #",
      "EMPTY_TEXT": "No members found. ",
      "EMPTY_TEXT_ACTION": "Clear search",
      "VIEW_MEMBER": "View member",
      "ADD_MEMBER": "Add member"
    },
    "AddMember": {
      "ADD_MEMBER": "Add member",
      "BILLING": "Billing",
      "MEMBER_INFO": "Member information",
      "EMPLOYMENT": "Employment",
      "DEPENDENTS": "Dependents",
      "BENEFITS": "Benefits",
      "REVIEW": "Review",
      "STEP_MENU": "Show step menu",
      "CANCEL": "Cancel",
      "BACK": "Back",
      "NEXT": "Next",
      "COMPLETE": "Complete enrollment",
      "ERROR_NOTIF": "Issues were found on this page. Please resolve the issues and submit the form again.",
      "STEP_OF": "Step {{currentStep}} of {{totalSteps}}"
    },
    "PersonalInfo": {
      "PERSONAL_INFO": "Personal information",
      "FIRST_NAME": "First name",
      "MIDDLE_NAME": "Middle name",
      "LAST_NAME": "Last name",
      "SUFFIX": "Suffix",
      "OPTIONAL": "Optional",
      "DOB": "Date of birth (MM/DD/YYYY)",
      "GENDER": "Gender",
      "MALE": "Male",
      "FEMALE": "Female",
      "PREF_NOT_SAY": "Prefer not to say",
      "SSN": "Social security number",
      "SSN_HELP_TXT": "Optional. Adding an SSN will provide a better member portal registration experience for the member.",
      "MAILING_ADDRESS": "Mailing address",
      "COUNTRY": "Country",
      "CANADA": "Canada",
      "USA": "United States",
      "ADDRESS": "Address",
      "ADDRESS_2": "Address line 2",
      "CITY": "City",
      "STATE": "State",
      "TERRITORY": "Territory/province",
      "ZIP": "Zip code",
      "POSTAL": "Postal code",
      "CONTACT": "Contact",
      "CONTACT_TXT": "We may use this to communicate with the member about ID cards, EOI and other important information.",
      "EMAIL": "Email",
      "PHONE_NUM": "Phone number",
      "PHONE_TYPE": "Phone type",
      "MOBILE": "Mobile",
      "HOME": "Home",
      "WORK": "Work",
      "PLEASE_MAKE_SELECTION": "Please make a selection",
      "REQUIRED": "This field is required",
      "INVALID": "Invalid characters",
      "INVALID_SSN": "Not a valid SSN",
      "INVALID_DOB": "Please enter a valid date of birth"
    },
    "Employment": {
      "HIRE_DATE": "Hire date",
      "HIRE_DATE_TXT": "Is this employee currently full time or part time?",
      "FULL_TIME_LABEL": "Full time hire date (MM/DD/YYYY)",
      "PART_TIME_LABEL": "Part time hire date (MM/DD/YYYY)",
      "FUTURE_DATE_ERROR": "Hire date cannot be more than 3 months in the future",
      "FULL_TIME": "Full time",
      "PART_TIME": "Part time",
      "ELIGIBLE_EMP_GRP": "Eligible employee group",
      "SIG_DATE": "Signature date",
      "SIG_DATE_LABEL": "Signature date (MM/DD/YYYY)",
      "SIG_DATE_TXT": "Provide the date that the member has agreed to enroll in Sun Life coverage. This determines if your member enrolled timely and whether they may be subject to EOI.",
      "PLEASE_MAKE_SELECTION": "Please make a selection",
      "INVALID_DATE": "Please enter a valid date"
    },
    "Billing": {
      "PLEASE_MAKE_SELECTION": "Please make a selection",
      "BILL_LOCATION": "Billing location",
      "BILL_LOC_TXT": "Billing location tells us which bill this member should be on."
    },
    "Review": {
      "REVIEW_TXT": "Please make sure the following information is correct before submitting.",
      "MEMBER_INFORMATION": "Member information",
      "EMPLOYMENT": "Employment",
      "DEPENDENTS": "Dependents",
      "SPOUSE": "Spouse / domestic partner",
      "CHILDREN": "Children",
      "BENEFITS": "Benefits",
      "TERMS_CONDITIONS": "Terms and conditions",
      "MEMBERGENDER": {
        "FEMALE": "Female",
        "MALE": "Male",
        "NOT_APPLICABLE": "N/A",
        "UNIDENTIFIED": "Unidentified",
        "UNKNOWN": "Unknown"
      },
      "YEARS_OLD_one": "{{count}} year old",
      "YEARS_OLD_other": "{{count}} years old"
    },
    "MemberDetail": {
      "MEMBER_BREADCRUMB": "Member listing",
      "MOBILE_BACK_BTN": "Back to member listing",
      "MEM_NUM_LABEL": "Member #",
      "MEM_MODIFIED_LABEL": "Last modified ",
      "DOWNLOAD_BTN": "Download ID cards",
      "ACTION_BTN": "Actions",
      "YES": "Yes",
      "NO": "No",
      "DETAIL_TITLE": "Personal information",
      "MEMBER_DOB": "Date of birth",
      "AGE_SUFFIX": "years old",
      "MEMBER_SSN": "SSN",
      "MEMBER_GENDER": "Gender",
      "MEMBERGENDER": {
        "FEMALE": "Female",
        "MALE": "Male",
        "NOT_APPLICABLE": "N/A",
        "UNIDENTIFIED": "Unidentified",
        "UNKNOWN": "Unknown"
      },
      "MEMBER_ADDRESS": "Address",
      "MEMBER_PREF_LANG": "Preferred language",
      "PREF_LANGUAGE": {
        "ara": "Arabic",
        "arm": "Armenian",
        "hye": "Armenian",
        "kmr": "Cambodian (Mon-Khmer)",
        "yue": "Cantonese",
        "chi": "Chinese",
        "zho": "Chinese",
        "eng": "English",
        "fas": "Farsi (Persian)",
        "hin": "Hindi",
        "hmn": "Hmong",
        "jpn": "Japanese",
        "kor": "Korean",
        "pan": "Punjabi",
        "rus": "Russian",
        "spa": "Spanish",
        "tgl": "Tagalog",
        "tha": "Thai",
        "vie": "Vietnamese"
      },
      "MEMBER_SMOKER": "Smoker",
      "MEMBER_DENTAL_ID": "Dental facility ID",
      "MEMBER_DOH": "Date of hire",
      "MEMBER_SALARY": "Salary",
      "EFFECTIVE": "effective",
      "PAID_FREQUENCY": "Paid ",
      "SALARY_FREQUENCY": {
        "LUNAR_MONTHLY": "monthly",
        "ANNUAL": "annually",
        "BIWEEKLY": "bi-weekly",
        "HOURLY": "hourly",
        "MONTHLY": "monthly",
        "QUARTERLY": "quarterly",
        "SEMI_ANNUAL": "semi-annually",
        "SEMI_MONTHLY": "semi-monthly",
        "WEEKLY": "weekly"
      },
      "AGE": "Age",
      "BASED_ON_EMP_AGE": "Based on employee age",
      "DEP_TITLE": "Dependents",
      "NO_BENEFITS": "This member has no benefits.",
      "BENEFITS_TITLE": "Benefits",
      "DENTAL_TITLE": "Dental",
      "VISION_TITLE": "Vision",
      "LIFE_TITLE": "Life",
      "LEAVE_SOL_TITLE": "Leave solutions",
      "HOSP_IND_TITLE": "Hospital indemnity",
      "ACCIDENT_TITLE": "Accident",
      "CRIT_ILL_TITLE": "Critical illness",
      "CANCER_TITLE": "Cancer",
      "DISABILITY_TITLE": "Disability",
      "EAP_TITLE": "Employee assistance program",
      "BENEFIT": "Benefit",
      "STATUS": "Status",
      "EFF_DATE": "Effective date",
      "PLAN": "Plan",
      "ISSUE_AGE": "Issue age",
      "MAX_DEP_AGE": "Max dependent age",
      "COVERAGE_AMT": "Coverage amount",
      "COVERED_AMT": "Covered amount",
      "PENDING_EOI": "Pending EOI",
      "REQUESTED_EXCESS": "Requested excess",
      "TOTAL_REQUESTED": "Total requested",
      "ACTIONS": "Actions",
      "POLICY_TITLE": "Policy information"
    },
    "BenefitCategories": {
      "HOSPITAL_INDEMNITY": "Hospital indemnity",
      "DENTAL": "Dental",
      "VISION": "Vision",
      "LIFE": "Life",
      "DISABILITY": "Disability",
      "EAP": "Employee assistance program",
      "LEAVE": "Leave solutions",
      "CANCER": "Cancer",
      "CRITICAL_ILLNESS": "Critical illness",
      "ACCIDENT": "Accident",
      "UNKNOWN": "Other"
    },
    "BenefitStatus": {
      "ALL": "All",
      "APPROVED_ACTIVE_SOON": "Approved: Active soon",
      "PENDING": "Pending EOI",
      "APPROVED_ACTIVE": "Approved: Active",
      "TERMINATING_SOON": "Terminating soon"
    },
    "Dependents": {
      "NO_DEPENDENTS": "We have no dependent details on file.\nIf this member is enrolled in dependent coverage, dependents are considered covered and will be confirmed at time of claim.",
      "DEP_INTRO": "All dependents are covered for dependent benefit lines unless otherwise specified in the below exclusions or they’ve aged out based on maximum dependent age rules. See policy documents for more detail. Additional dependents covered under the plan will show here after a claim has been submitted for them.",
      "DEP_NAME": "Name",
      "DEP_DOB": " Date of Birth",
      "DEP_RELATIONSHIP": "Relationship",
      "DEP_SSN": "SSN",
      "DEP_GENDER": "Gender",
      "DEP_DENTAL_ID": "Dental Facility ID",
      "DEP_SMOKER": "Smoker",
      "CHILDREN": "Children",
      "ADD_DEP_INTRO": "Add dependents now if your employee is going to be enrolling their family members.",
      "RELATIONSHIP": {
        "SPOUSE": "Spouse / domestic partner",
        "DEPENDENT": "Child",
        "DOMESTIC_PARTNER": "Domestic partner",
        "OTHER": "Other"
      },
      "MEMBERGENDER": {
        "FEMALE": "Female",
        "MALE": "Male",
        "NOT_APPLICABLE": "N/A",
        "UNIDENTIFIED": "Unidentified",
        "UNKNOWN": "Unknown"
      },
      "YES": "Yes",
      "NO": "No",
      "YEARS_OLD_one": "{{count}} year old",
      "YEARS_OLD_other": "{{count}} years old"
    },
    "Benefits": {
      "NO_BENEFIT_ELECTED": "All benefits must be either waived or elected.",
      "EMPLOYEE": "Employee",
      "SPOUSE": "Employee + spouse / domestic partner",
      "CHILD": "Employee + child(ren)",
      "FAMILY": "Employee + family",
      "PLEASE_MAKE_SELECTION": "Please make a selection"
    },
    "Policies": {
      "MEMBER_POLICY_NUMBER": "Policy #",
      "MEMBER_BILL_LOCATION": "Billing location(s)",
      "MEMBER_ELIGIBLE_EMP_GRP": "Eligible employee group",
      "MEMBER_DEPT": "Department",
      "MEMBER_BENEFITS": "Benefits"
    }
  }
}
