import { type ReactNode, useState } from 'react'

export type AddMemberStepId = 'member_info' | 'employment' | 'dependents' | 'benefits' | 'billing' | 'review'
export type AddMemberGuidedStepStatus = 'COMPLETED' | 'NOT_STARTED' | 'STARTED'
export type AddMemberGuidedStep = {
  id: AddMemberStepId
  label: string
  status: AddMemberGuidedStepStatus
  title: string
  content: ReactNode
  isStepRequired: () => boolean
}

export const useGuidedStepId = (defaultStep: AddMemberStepId = 'member_info') => useState<AddMemberStepId>(defaultStep)
