import { Body } from '@component-library/helios'
import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { termsConditions } from '../../../common/text/termsConditionsText'

import * as styles from './Review.module.css'

export const TermsConditions: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'Review' })

  return (
    <>
      <Body variant="medium" data-testid="termsConditions">
        <div className="mt-4 mb-4">
          <Body variant="medium-bold">{termsConditions.firstStatement}</Body>
        </div>
        <div className="mt-4 mb-4">{termsConditions.listTitle}</div>
        <ul>
          <li>{termsConditions.list.one}</li>
          <li>{termsConditions.list.two}</li>
          <li>{termsConditions.list.three}</li>
          <li>{termsConditions.list.four}</li>
          <li>{termsConditions.list.five}</li>
        </ul>
        <div className="mt-4 mb-4">{termsConditions.secondStatement}</div>
        <div className="mt-4 mb-4">{termsConditions.ackStatement}</div>
      </Body>
    </>
  )
}
