import { DatePicker, HelperText } from '@component-library/helios'
import { DateTime } from 'luxon'
import { type FC, useRef } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { DATE_OF_BIRTH } from '../../../../common/enums/regex'

import * as styles from './PersonalInfo.module.css'

const {
  MM_DD_YYYY: { regex, pattern },
} = DATE_OF_BIRTH

interface DateOfBirthProps {
  minAge?: number
  maxAge?: number
}

export const DateOfBirth: FC<DateOfBirthProps> = ({ minAge = 16, maxAge = 99 }: DateOfBirthProps) => {
  const { control } = useFormContext()
  const { t } = useTranslation('translation', { keyPrefix: 'PersonalInfo' })
  const inputRef = useRef<HTMLInputElement>(null)

  const isDateValid = (dateString: { input: string }, minAge: number, maxAge: number): boolean => {
    const today = DateTime.now()
    const dob = DateTime.fromFormat(dateString.input, 'MM/dd/yyyy')

    if (dob.invalidReason) {
      return false
    }

    if (dob > today.minus({ years: minAge })) {
      return false
    }

    if (dob < today.minus({ years: maxAge })) {
      return false
    }

    return true
  }

  const validateDateOfBirth = (dateString: { input: string }): true | string => {
    if (dateString.input === '') {
      return t('PLEASE_MAKE_SELECTION')
    }
    if (isDateValid(dateString, minAge, maxAge)) {
      return true
    }
    return t('INVALID_DOB')
  }

  return (
    <>
      <div className={styles.textBoxMargin} data-testid="dob">
        <Controller
          rules={{
            required: {
              value: true,
              message: t('PLEASE_MAKE_SELECTION'),
            },
            pattern: {
              value: DATE_OF_BIRTH.MM_DD_YYYY.regex,
              message: DATE_OF_BIRTH.MM_DD_YYYY.message,
            },
            validate: validateDateOfBirth,
          }}
          control={control}
          name="dateOfBirth"
          render={({ field, fieldState }) => {
            const { ...fields } = field
            return (
              <>
                <div>
                  <DatePicker
                    {...fields}
                    id={field.name}
                    label={t('DOB')}
                    error={fieldState?.invalid}
                    className=""
                    placeholder={''}
                    defaultValue={undefined}
                    minDate=""
                    maxDate=""
                    isModal={false}
                    inputProps={undefined}
                    inputRef={inputRef}
                    localeTexts={undefined}
                  />
                </div>
                {fieldState.invalid && (
                  <div>
                    <HelperText id={`dob-error`} error>
                      {fieldState.error?.message}
                    </HelperText>
                  </div>
                )}
              </>
            )
          }}
        />
      </div>
    </>
  )
}
