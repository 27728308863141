import { TextField } from '@component-library/helios'
import type { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { EMAIL_REGEX } from '../../../../common/enums/regex'
import * as styles from '../PersonalInfo/PersonalInfo.module.css'

export const Email: FC = () => {
  const { control } = useFormContext()
  const { t } = useTranslation('translation', { keyPrefix: 'PersonalInfo' })

  return (
    <>
      <div className={styles.textBoxMargin} data-testid="email">
        <Controller
          render={({ field, fieldState }) => {
            const { onChange, ...fields } = field
            return (
              <TextField
                {...fields}
                id={field.name}
                icon="fal fa-envelope"
                iconPosition="before"
                label={t('EMAIL')}
                error={fieldState.invalid}
                helperText={fieldState.error ? fieldState.error?.message : t('OPTIONAL')}
                maxLength={30}
                onChange={(e) => {
                  onChange(e)
                }}
              />
            )
          }}
          rules={{
            required: {
              value: false,
              message: '',
            },
            pattern: {
              value: EMAIL_REGEX,
              message: t('INVALID'),
            },
          }}
          control={control}
          name="email"
        />
      </div>
    </>
  )
}
