import { useQuery } from '@tanstack/react-query'
import type { Member_ListRequestQueryParams } from 'types/domain'

import { queryKeys } from '../../utils/queryKeys'
import { endpoints } from '../endpoints'

export type UseGetMembersReturn = ReturnType<typeof useGetMembers>

const request = endpoints.Member_List.getApiRequest()

export const useGetMembers = (
  { query }: { query?: Member_ListRequestQueryParams },
  { enabled }: { enabled: boolean } = { enabled: true },
) => {
  //write member list params to sessionStorage so they can be reapplied when returning from the detail page
  window.sessionStorage.setItem('memberListPaginaton', JSON.stringify(query))
  return useQuery({
    queryKey: queryKeys.getMembers(query),
    queryFn: () => request({ query }),
    enabled,
  })
}
