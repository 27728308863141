import { HelperText, Link, Progress } from '@component-library/helios'
import type { UseQueryResult } from '@tanstack/react-query'
import { type ReactNode } from 'react'

import * as styles from './QueryResultUnconditional.module.css'

export type QueryResultUnconditionalProps<TData = unknown, TError = unknown> = {
  query: UseQueryResult<TData, TError>
  children: (data?: TData) => ReactNode
  loading?: ReactNode
  error?: ReactNode
}

export function QueryResultUnconditional<TData = unknown, TError = unknown>({
  query,
  children,
  loading,
  error,
}: QueryResultUnconditionalProps<TData, TError>) {
  const dataLoading = (query.isLoading || query.isFetching) && !query.isRefetching ? 'true' : 'false'
  const dataError = query.isError ? 'true' : 'false'

  return (
    <>
      <div className={styles.loadingContainer} data-loading={dataLoading}>
        {loading ?? (
          <div className={styles.loading}>
            <Progress id="queryResultLoading" label="" variant="indeterminate" />
          </div>
        )}
      </div>
      <div className={styles.errorContainer} data-error={dataError}>
        {error ?? (
          <div className={styles.error}>
            <HelperText id="loadingError" error>
              <span>An error has occurred.</span>
              &nbsp;
              <Link href="#" variant="primary" size="sm" onClick={() => query.refetch()}>
                Try again
              </Link>
            </HelperText>
          </div>
        )}
      </div>
      <div className={styles.resultContainer} data-loading={dataLoading} data-error={dataError}>
        {children(query.data)}
      </div>
    </>
  )
}
