.helpWrapper {
  padding-top: 3rem;
}

.removeMargins {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.titleCtr {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 575.98px) {
  .addBtn {
    width: 50%;
  }
}
