import { matchPath } from 'react-router-dom'

type PageMeta = {
  category: string
  subCategory: string
}

// TODO add routes to a typescript string union or enum

const pageMeta: Record<string, PageMeta> = {
  '/members': { category: 'Employer Member Management', subCategory: 'Member List' },
  '/member/:memberId/*': {
    category: 'Employer Member Management',
    subCategory: 'Member Detail',
  },
  '/logout': { category: 'Logout', subCategory: 'Logout' },
  '/': { category: 'Home', subCategory: 'Home' },
}

export const getPageMeta = (pathname: string) => {
  for (const pattern of Object.keys(pageMeta)) {
    if (matchPath(pattern, pathname)) {
      return pageMeta[pattern]
    }
  }
}
