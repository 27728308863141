.textBoxMargin {
  margin: 1.5rem 0px;
}

.halfRow {
  width: 45%;
}

.phoneCtr {
  display: flex;
  justify-content: space-between;
  margin-top: -2rem;
}

.threeQuarRow {
  width: 58%;
}

.oneQuarRow {
  width: 38%;
}

.halfRow {
  width: 45%;
}

.phoneCtr {
  display: flex;
  justify-content: space-between;
  margin-top: -2rem;
}

.threeQuarRow {
  width: 58%;
}

.oneQuarRow {
  width: 38%;
}
