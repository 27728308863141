export const cities = [
  { value: 'New York', label: 'New York' },
  { value: 'Los Angeles', label: 'Los Angeles' },
  { value: 'Chicago', label: 'Chicago' },
  { value: 'Houston', label: 'Houston' },
  { value: 'Phoenix', label: 'Phoenix' },
  { value: 'Philadelphia', label: 'Philadelphia' },
  { value: 'San Antonio', label: 'San Antonio' },
  { value: 'San Diego', label: 'San Diego' },
  { value: 'Dallas', label: 'Dallas' },
  { value: 'San Jose', label: 'San Jose' },
  { value: 'Austin', label: 'Austin' },
  { value: 'Jacksonville', label: 'Jacksonville' },
  { value: 'Fort Worth', label: 'Fort Worth' },
  { value: 'Columbus', label: 'Columbus' },
  { value: 'Charlotte', label: 'Charlotte' },
  { value: 'Detroit', label: 'Detroit' },
  { value: 'El Paso', label: 'El Paso' },
  { value: 'Memphis', label: 'Memphis' },
  { value: 'Denver', label: 'Denver' },
  { value: 'Washington', label: 'Washington' },
]
