import { TextField } from '@component-library/helios'
import type { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { NAME_FIELDS_REGEX } from '../../../../common/enums/regex'

import * as styles from './PersonalInfo.module.css'

export const Name: FC = () => {
  const { control } = useFormContext()
  const { t } = useTranslation('translation', { keyPrefix: 'PersonalInfo' })

  return (
    <>
      <div className={styles.textBoxMargin} data-testid="firstName">
        <Controller
          render={({ field, fieldState }) => {
            const { onChange, ...fields } = field
            return (
              <TextField
                {...fields}
                id={field.name}
                label={t('FIRST_NAME')}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                maxLength={20}
                onChange={(e) => {
                  onChange(e)
                }}
              />
            )
          }}
          rules={{
            required: {
              value: true,
              message: t('REQUIRED'),
            },
            pattern: {
              value: NAME_FIELDS_REGEX,
              message: t('INVALID'),
            },
          }}
          control={control}
          name="firstName"
        />
      </div>
      <div className={styles.textBoxMargin} data-testid="midName">
        <Controller
          render={({ field, fieldState }) => {
            const { onChange, ...fields } = field
            return (
              <TextField
                {...fields}
                id={field.name}
                label={t('MIDDLE_NAME')}
                error={fieldState.invalid}
                helperText={fieldState.error ? fieldState.error?.message : t('OPTIONAL')}
                maxLength={20}
                onChange={(e) => {
                  onChange(e)
                }}
              />
            )
          }}
          rules={{
            required: {
              value: false,
              message: '',
            },
            pattern: {
              value: NAME_FIELDS_REGEX,
              message: t('INVALID'),
            },
          }}
          control={control}
          name="middleName"
        />
      </div>
      <div className={styles.textBoxMargin} data-testid="lastName">
        <Controller
          render={({ field, fieldState }) => {
            const { onChange, ...fields } = field
            return (
              <TextField
                {...fields}
                id={field.name}
                label={t('LAST_NAME')}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                maxLength={20}
                onChange={(e) => {
                  onChange(e)
                }}
              />
            )
          }}
          rules={{
            required: {
              value: true,
              message: t('REQUIRED'),
            },
            pattern: {
              value: NAME_FIELDS_REGEX,
              message: t('INVALID'),
            },
          }}
          control={control}
          name="lastName"
        />
      </div>
      <div className={styles.textBoxMargin} data-testid="suffix">
        <Controller
          render={({ field, fieldState }) => {
            const { onChange, ...fields } = field
            return (
              <TextField
                {...fields}
                id={field.name}
                label={t('SUFFIX')}
                error={fieldState.invalid}
                helperText={fieldState.error ? fieldState.error?.message : t('OPTIONAL')}
                maxLength={3}
                onChange={(e) => {
                  onChange(e)
                }}
              />
            )
          }}
          rules={{
            required: {
              value: false,
              message: '',
            },
            pattern: {
              value: NAME_FIELDS_REGEX,
              message: t('INVALID'),
            },
          }}
          control={control}
          name="suffix"
        />
      </div>
    </>
  )
}
