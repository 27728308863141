/* Customizations for TopNav Notification Banner
This banner is temporary and will be removed once more integrated with SLC. */

.exitnotification button {
  display: none !important;
}

.exitnotification [class='container'] {
  display: flex;
  justify-content: center;
}

.exitnotification [class='notification-icon'] {
  display: none;
}

.exitnotification [class='notification-text'] {
  text-align: center;
  a {
    white-space: initial;
  }
}

/* .exitnotification [role='alert'] {
  padding: none;
} */

.exitnotification [role='alert'] {
  box-shadow: none !important;
  border-left: none;
}

@media (max-width: 575.98px) {
  .exitnotification [class='notification-text'] {
    font-size: 12px;
    line-height: 1rem;
    margin-right: 0;
    a {
      font-size: 12px;
      line-height: 1rem;
    }
  }
}

/* End of customizations for TopNav Notification Banner */
