import { DateTime } from 'luxon'

export const SESSION_EXPIRATION_TIMEOUT_IN_MINUTES = 15
const SESSION_WILL_EXPIRE_TIMEOUT_IN_MINUTES = 13

type AuthTokenActivity = {
  status: 'active' | 'will-expire' | 'expired'
  remainingTime: string
}

/**
 * Returns the auth token activity.
 * @param {AuthToken} token The Auth Service SID.
 * @returns {AuthTokenActivity} An object with the following property:
 *  - `status`: 'active', 'will-expire', or 'expired'.
 *  - `remainingTime`: The remaining time in the format 'm'm' s's'.
 */
export const getAuthTokenActivity = (token?: AuthToken): AuthTokenActivity => {
  if (!token) {
    return { status: 'expired', remainingTime: '0m 0s' }
  }

  const now = DateTime.now()
  const createdAt = DateTime.fromSeconds(token.createdAt)
  const minutesEllapsed = now.diff(createdAt, 'minutes').minutes

  if (minutesEllapsed > SESSION_EXPIRATION_TIMEOUT_IN_MINUTES) {
    return { status: 'expired', remainingTime: '0m 0s' }
  }

  const expirationDate = createdAt.plus({ minutes: SESSION_EXPIRATION_TIMEOUT_IN_MINUTES })
  const remaining = expirationDate.diff(now)
  const remainingTime = remaining.toFormat("m'm' s's'") // e.g. 4m 59s

  if (minutesEllapsed > SESSION_WILL_EXPIRE_TIMEOUT_IN_MINUTES) {
    return { status: 'will-expire', remainingTime }
  }

  return { status: 'active', remainingTime }
}
