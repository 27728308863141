import { Body, Link } from '@component-library/helios'
import { type ReactNode } from 'react'

import * as styles from './EmptyState.module.css'

interface EmptyStateProps {
  emptyText?: ReactNode
  displayClear?: boolean
  clearText?: ReactNode
  clearFilter?: () => void
}

export const EmptyState = ({ emptyText, displayClear = true, clearText = 'Clear', clearFilter }: EmptyStateProps) => {
  function handleEmptyStateAction() {
    clearFilter && clearFilter()
  }

  if (emptyText == null) return

  return (
    <div className={styles.emptyState}>
      <Body>
        {emptyText}
        {displayClear && (
          <Link size="sm">
            <span onClick={() => handleEmptyStateAction()}>{clearText}</span>
          </Link>
        )}
      </Body>
    </div>
  )
}
