import { TextField } from '@component-library/helios'
import type { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { USA_ZIP_REGEX, CANADA_POST_REGEX } from '../../../../common/enums/regex'
import * as styles from '../PersonalInfo/PersonalInfo.module.css'

type ZipProps = {
  isCanada: boolean
}

export const Zip: FC<ZipProps> = ({ isCanada }: ZipProps) => {
  const { control } = useFormContext()
  const { t } = useTranslation('translation', { keyPrefix: 'PersonalInfo' })

  return (
    <>
      <div className={styles.textBoxMargin + ' ' + styles.halfRow} data-testid="zip">
        <Controller
          render={({ field, fieldState }) => {
            const { onChange, ...fields } = field
            return (
              <TextField
                {...fields}
                id={field.name}
                label={isCanada ? t('POSTAL') : t('ZIP')}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                maxLength={isCanada ? 7 : 10}
                onChange={(e) => {
                  onChange(e)
                }}
              />
            )
          }}
          rules={{
            required: {
              value: true,
              message: t('REQUIRED'),
            },
            pattern: {
              value: isCanada ? CANADA_POST_REGEX : USA_ZIP_REGEX,
              message: t('INVALID'),
            },
          }}
          control={control}
          name="zip"
        />
      </div>
    </>
  )
}
